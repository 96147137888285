<div class="modal fade modal-RoomAdditionalDays" id="modalMyPurhcaseDetail" data-bs-backdrop="static" tabindex="-1"
    aria-labelledby="modalMyPurhcaseDetailLabel" aria-hidden="true">
    <div class="modal-dialog modal-fullscreen">
        <div class="modal-content">

            <div class="modal-header justify-content-center">
                <h5 class="modal-title fw-bold text-uppercase">
                    {{ "general.purchasingSummary" | translate | uppercase }}
                </h5>

            </div>

            <div class="modal-body" id="alldata">
                <div class="container">
                    <div class="row g-3">

                        <div #screen>

                        
                        <div class="col-12 text-center mb-2">
                            <h5>{{'general.order' | translate | titlecase}} <strong>#{{order?.orderId}}</strong></h5>
                            <h5>{{(order?.createdAt | date:'medium') || 'MMM d, y, h:mm:ss a' }}</h5>
                        </div>


                        <div class="col-12 mb-4" *ngFor="let item of rooms; index as idx">
                            <app-purchase-summary-details-card-item [index]="item.index" [item]="item">
                            </app-purchase-summary-details-card-item>
                        </div>

                        <div class="col-12 mb-4">
                            <app-purchase-summary-details-category-card-item [order]="order">
                            </app-purchase-summary-details-category-card-item>
                        </div>

                        <div class="col-12 mb-4">
                            <app-purchase-summary-details-event-pass-card-item [order]="order">
                            </app-purchase-summary-details-event-pass-card-item>
                        </div>

                        <div  *ngIf="order?.installments.length > 0">
                            <div class="col-12 mb-4" *ngFor="let item of order.installments">
                                <app-pre-sale-installment-couta-card-item 
                                    [index]="item.nro" [item]="item" [showButton]="true">
                                </app-pre-sale-installment-couta-card-item>
                            </div>
                        </div>

                        <app-purchase-summary-totales *ngIf="order" [order]="order"></app-purchase-summary-totales>
                    </div>
                        <div class="col-12 mb-4" *ngIf="order?.paymentMethodType === 'bankTransfer'">
                            <app-card-bank-transfer [order]="order"></app-card-bank-transfer>
                        </div>
                        

                        <div class="col-12">
                            <div class="d-flex justify-content-center justify-content-md-end">
                                <!-- <button class="btn btn-three" type="button" (click)="share()">
                                    <i class="bi bi-share"></i>
                                    COMPARTIR
                                </button> -->
                                &nbsp;
                                <button class="btn btn-three text-uppercase" type="button" (click)="downloadImage()">
                                    <i class="bi bi-cloud-download"></i>
                                    {{ "general.download" | translate }}
                                </button>
                            </div>
                        </div>

                            <ng-container *ngIf="order?.status === 'pending' && auth === 'adm'">
                                <div class="col-6">
                                    <div class="d-flex justify-content-end">
                                        <div class="w-50 d-flex flex-row justify-content-center align-items-center">
                                            <button type="button" class="btn btn-one" (click)="completedOrder(order)">
                                                {{ "general.confirmOrder" | translate }}
                                            </button>
                                        </div>
                                    </div>
                                </div>
        
                                <div class="col-6">
                                    <div class="d-flex justify-content-start">
                                        <div class="w-50 d-flex flex-row justify-content-center align-items-center">
                                            <button type="button" class="btn btn-one" (click)="cancelOrder(order)">
                                                {{ "general.rejectedOrder" | translate }}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>

                    </div>
                </div>
            </div>

            <div class="modal-footer mt-2 flex-column justify-content-center">
                <div class="w-50 d-flex flex-row justify-content-center align-items-center">
                    <button type="button" class="btn btn-one" (click)="closeModal()">
                        {{ "general.back" | translate }}
                    </button>
                </div>
            </div>

        </div>
    </div>
</div>