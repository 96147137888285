<div class="row mb-4">
  <div class="col-12 mb-4">
    <p>
      <!-- Para brindarte flexibilidad y confianza al realizar tus transacciones en línea, te ofrecemos la opción de
            pagar a través de TuCompra, una plataforma líder en soluciones de pagos electrónicos: -->
      {{ "checkout.text" | translate }}
    </p>
  </div>
</div>

<form
  class="mb-5"
  [formGroup]="form"
  (ngSubmit)="onSubmit()"
  #userForm
  novalidate
>
  <div class="row">
    <div class="col-12 mb-3">
      <label for="tipoDocumento" class="form-label">
        {{ "general.documentType" | translate }}
      </label>
      <select class="form-select" formControlName="tipoDocumento">
        <option value="" selected>{{ "general.select" | translate }}...</option>
        <option value="CC">{{ "general.cc" | translate }}</option>
        <option value="CE">{{ "general.ce" | translate }}</option>
        <option value="NIT">{{ "general.nit" | translate }}</option>
        <option value="PAS">{{ "general.passport" | translate }}</option>
      </select>
      <ng-container
        *ngIf="submitted"
        [ngTemplateOutlet]="renderFormVM"
        [ngTemplateOutletContext]="{ field: 'tipoDocumento' }"
      ></ng-container>
    </div>

    <div class="col-12 mb-3">
      <label for="documentoComprador" class="form-label">
        {{ "general.cedula" | translate }}
      </label>
      <input
        type="text"
        class="form-control"
        formControlName="documentoComprador"
        aria-describedby="documentoCompradorHelp"
      />
      <ng-container
        *ngIf="submitted"
        [ngTemplateOutlet]="renderFormVM"
        [ngTemplateOutletContext]="{ field: 'documentoComprador' }"
      ></ng-container>
    </div>

    <div class="col-12 mb-3">
      <label for="nombreComprador" class="form-label">
        {{ "general.name" | translate }}
      </label>
      <input
        type="text"
        class="form-control"
        formControlName="nombreComprador"
        aria-describedby="nombreCompradorHelp"
      />
      <ng-container
        *ngIf="submitted"
        [ngTemplateOutlet]="renderFormVM"
        [ngTemplateOutletContext]="{ field: 'nombreComprador' }"
      ></ng-container>
    </div>

    <div class="col-12 mb-3">
      <label for="apellidoComprador" class="form-label">
        {{ "general.lastName" | translate }}
      </label>
      <input
        type="text"
        class="form-control"
        formControlName="apellidoComprador"
        aria-describedby="apellidoCompradorHelp"
      />
      <ng-container
        *ngIf="submitted"
        [ngTemplateOutlet]="renderFormVM"
        [ngTemplateOutletContext]="{ field: 'apellidoComprador' }"
      ></ng-container>
    </div>

    <div class="col-12 mb-3">
      <label for="correoComprador" class="form-label">
        {{ "general.email" | translate }}
      </label>
      <input
        type="text"
        class="form-control"
        formControlName="correoComprador"
        aria-describedby="correoCompradorHelp"
      />
      <ng-container
        *ngIf="submitted"
        [ngTemplateOutlet]="renderFormVM"
        [ngTemplateOutletContext]="{ field: 'correoComprador' }"
      ></ng-container>
    </div>

    <div class="col-12 mb-3">
      <label for="celularComprador" class="form-label">
        {{ "general.phoneNumber" | translate }}
      </label>
      <input
        type="text"
        class="form-control"
        formControlName="celularComprador"
        aria-describedby="celularCompradorHelp"
      />
      <ng-container
        *ngIf="submitted"
        [ngTemplateOutlet]="renderFormVM"
        [ngTemplateOutletContext]="{ field: 'celularComprador' }"
      ></ng-container>
    </div>

    <div class="col-12 mb-3">
      <label for="direccionComprador" class="form-label">
        {{ "general.address" | translate }}
      </label>
      <input
        type="text"
        class="form-control"
        formControlName="direccionComprador"
        aria-describedby="direccionCompradorHelp"
      />
      <ng-container
        *ngIf="submitted"
        [ngTemplateOutlet]="renderFormVM"
        [ngTemplateOutletContext]="{ field: 'direccionComprador' }"
      ></ng-container>
    </div>

    <div class="col-12 text-center">
      <ng-container
        *ngIf="!showLoader; then renderSubmitBtn; else renderLoaderBtn"
      ></ng-container>
    </div>
  </div>
</form>

<ng-template #renderSubmitBtn>
  <button type="submit" class="btn btn-danger">
    {{ "general.payWith" | translate }} tuCompra
  </button>
</ng-template>

<ng-template #renderLoaderBtn>
  <button type="submit" class="btn btn-danger disabled">
    <div class="text-center">
      <div class="spinner-border spinner-border-sm" role="status">
        <span class="visually-hidden"
          >{{ "general.loading" | translate }}...</span
        >
      </div>
    </div>
  </button>
</ng-template>

<ng-template #renderFormVM let-field="field">
  <ng-container *ngFor="let val of vm[field]">
    <div
      id="{{ field }}Help"
      class="form-text text-danger"
      *ngIf="f[field].hasError(val.type)"
    >
      {{ val.message | translate | titlecase }}
    </div>
  </ng-container>
</ng-template>
