<div class="card card-box p-3 mb-3">
    <div class="row g-2 d-flex align-items-center">
        <div class="col-12 px-3">
            <div class="icon-p">
                <ng-container [ngSwitch]="item.paymentMethod">

                    <ng-container *ngSwitchCase="'installments'">
                        <i class="bi bi-alarm"></i>
                    </ng-container>
                    <ng-container *ngSwitchCase="'bankTransfer'">
                        <i class="bi bi-bank"></i>
                    </ng-container>

                    <ng-container *ngSwitchCase="'tucompra'">
                        <i class="bi bi-card-heading"></i>
                    </ng-container>

                    <ng-container *ngSwitchCase="'paypal'">
                        <i class="bi bi-paypal"></i>
                    </ng-container>

                    <ng-container *ngSwitchDefault>
                        <i class="bi bi-cart3"></i>
                    </ng-container>
                </ng-container>
            </div>

            <div class="">

                <div *ngIf="item.uid  | objUser |async as user">
                    <div class="fw-bold fs-5">
                        <!-- <i class="bi bi-cart3"></i>
                        &nbsp; -->
                        {{user.name | titlecase}}
                    </div>
                    <div class="card-title fs-6 fw-bolder">
                        {{user.email}}
                    </div>
                    <div class="card-title fs-6 fw-bolder">
                        {{user.prefijo}} {{user.phone }}
                    </div>
                    <div class="card-title fs-6 fw-bolder">
                        {{item.createdAt | date: 'medium'}}
                    </div>
                </div>


                <!-- {{item.uid | profile | async | titlecase}} |&nbsp; -->
                {{item.paymentMethod | translate | titlecase }} | {{item.createdAt | date: 'medium'}} |
            </div>
            <!-- <h5 class="card-title fw-bolder text-rslt">
                <span class="placeholder col-12"></span>
            </h5> -->
        </div>

        <div class="seprd2"></div>

        <div class="col-12 px-3">
            <div class="row mb-1">
                <div class="col-12 col-md-6">
                    <p class="card-text mb-0">
                        {{'general.orden_id'| translate | titlecase}}:
                        &nbsp;
                        <small class="text-muted">
                            {{item._id}}
                        </small>
                    </p>
                </div>
                <div class="col-12 col-md-6">
                    <p class="card-text mb-0 text-md-end">
                        <span>{{ "general.status" | translate }}:</span>
                        <span class="badge bg-danger" [ngClass]="{'success': item.status === 'completed'}">
                            {{ ('general.'+ ((item.status === 'completed') ? 'approved' : item.status)) | translate |
                            uppercase}}
                        </span>
                    </p>
                </div>
            </div>

            <div class="d-flex flex-row align-content-center justify-content-between">
                <div class="d-flex justify-content-center align-items-center">
                    <span class="fs-4 fw-bolder">{{ "general.total" | translate }}: USD <span
                            class="text-rslt">{{item.totales}}</span></span>
                </div>
                <div>
                    <button type="button" class="btn-icon btn-icon-two" (click)="goToDetails()">
                        <i class="bi bi-info-circle"></i>
                    </button>
                </div>
            </div>

        </div>
    </div>
</div>