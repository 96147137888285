<div class="modal fade modal-PlanDetails" id="modalPlanDetails" tabindex="-1" aria-labelledby="modalPlanDetails"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content modal-custom p-5">
            <div class="modal-header d-flex justify-content-center">
                <div class="container">
                    <div class="row">
                        <div class="col">
                            <div class="logo">
                                <img src="assets/img/cilik/logo.png" alt="">
                            </div>
                        </div>
                        <div class="col text-end">
                            <span class="fs-2 fw-bold text-end">
                                {{ "WLDCPlanIncludes.title" | translate }} <br> WLDC 2023
                            </span>
                        </div>
                    </div>
                </div>
                
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>

            </div>
            <div class="modal-body">
                <p class="text-justify fw-bold fs-5">
                    {{ "WLDCPlanIncludes.subTitle" | translate }}
                    
                </p>
                <div class="list-details">
                    <div class="item-l-d d-flex fw-bold align-items-center mb-3">
                        <div class="icon-l-d d-flex justify-content-center align-items-center pe-3">
                            <img src="assets/img/cilik/icon-38-2.png" alt="">
                        </div>
                        <div class="">
                            {{ ("WLDCPlanIncludes.description" | translate)[0] }}
                        </div>
                    </div>
                    <div class="item-l-d d-flex fw-bold align-items-center mb-3">
                        <div class="icon-l-d d-flex justify-content-center align-items-center pe-3">
                            <img src="assets/img/cilik/icon-37.png" alt="">
                        </div>
                        <div class="">
                            {{ ("WLDCPlanIncludes.description" | translate)[1] }}
                        </div>
                    </div>
                    <div class="item-l-d d-flex fw-bold align-items-center mb-3">
                        <div class="icon-l-d d-flex justify-content-center align-items-center pe-3">
                            <img src="assets/img/cilik/icon-36.png" alt="">
                        </div>
                        <div class="">
                            {{ ("WLDCPlanIncludes.description" | translate)[2] }}
                        </div>
                    </div>
                    <div class="item-l-d d-flex fw-bold align-items-center mb-3">
                        <div class="icon-l-d d-flex justify-content-center align-items-center pe-3">
                            <img src="assets/img/cilik/icon-35.png" alt="">
                        </div>
                        <div class="">
                            {{ ("WLDCPlanIncludes.description" | translate)[3] }}
                        </div>
                    </div>
                    <div class="item-l-d d-flex fw-bold align-items-center mb-3">
                        <div class="icon-l-d d-flex justify-content-center align-items-center pe-3">
                            <img src="assets/img/cilik/icon-34.png" alt="">
                        </div>
                        <div class="">
                            {{ ("WLDCPlanIncludes.description" | translate)[4] }}
                        </div>
                    </div>
                    <div class="seprd2"></div>
                    <div class="item-l-d d-flex my-3 fw-bold">
                        <div class="icon-l-d d-flex justify-content-center align-items-center pe-3">
                            <img src="assets/img/cilik/icon-33.png" alt="">
                        </div>
                        <div class="">
                            <span class="text-rslt">
                                {{ ("WLDCPlanIncludes.description" | translate)[5] }}
                            </span> 
                            {{ ("WLDCPlanIncludes.description" | translate)[6] }}
                        </div>
                    </div>
                    <div class="seprd2"></div>
                    <div class="fs-5 fw-bold py-3">
                        {{ "WLDCPlanIncludes.firstLapBuyBenefits.title" | translate }}
                    </div>
                    <div class="item-l-d d-flex fw-bold align-items-center">
                        <div class="icon-l-d d-flex justify-content-center align-items-center pe-3">
                            <i class="bi bi-check2"></i>
                        </div>
                        <div class="">
                            {{ ("WLDCPlanIncludes.firstLapBuyBenefits.description" | translate)[0] }}
                        </div>
                    </div>
                    <div class="item-l-d d-flex fw-bold align-items-center">
                        <div class="icon-l-d d-flex justify-content-center align-items-center pe-3">
                            <i class="bi bi-check2"></i>
                        </div>
                        <div class="">
                            {{ ("WLDCPlanIncludes.firstLapBuyBenefits.description" | translate)[1] }}
                        </div>
                    </div>
                    <div class="item-l-d d-flex fw-bold align-items-center">
                        <div class="icon-l-d d-flex justify-content-center align-items-center pe-3">
                            <i class="bi bi-check2"></i>
                        </div>
                        <div class="">
                            {{ ("WLDCPlanIncludes.firstLapBuyBenefits.description" | translate)[2] }}
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>