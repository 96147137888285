<div class="card card-box p-3 mb-3">
    <div class="row g-2 d-flex align-items-center">
        <div class="col-12 px-3">
            <!-- <h5 class="card-title fw-bolder text-rslt">
                <i class="bi bi-ticket-perforated"></i>
                &nbsp;
                {{item?.title}}
            </h5> -->
            <div class="d-flex justify-content-between">
                <!-- <span class="text-rslt fw-bold fs-5">
                    <i class="bi bi-ticket-perforated"></i>
                    &nbsp;
                    {{item?.title | translate}}
                </span>

                <span class="d-none d-md-block">
                    <i class="bi bi-person-fill"></i>
                    {{item?.capacity}}
                </span> -->

                <span class="text-rslt fw-bold fs-6">
                    <i class="bi bi-person-fill"></i>
                    {{item?.capacity}}
                    |
                    {{item?.title | translate}}
                </span>

                <div class="c-icon d-flex align-items-center">
                    <img src="assets/img/icon-full-pass-gray.png" alt="">
                </div>
            </div>
        </div>

        <div class="seprd2"></div>

        <div class="col-12 px-3">

            <p class="m-0 d-block d-sm-none">
                <i class="bi bi-person-fill"></i>
                {{item?.capacity}}
            </p>

            <p class="card-text mb-0" *ngIf="item?.include">
                {{"general.include" | translate}}:
                <small class="text-muted">
                    {{item.include | translate}}
                </small>
            </p>

            <p class="card-text mb-0" *ngIf="item?.notInclude">
                {{"general.notInclude" | translate}}:
                <small class="text-muted">
                    {{item.notInclude | translate}}
                </small>
            </p>

            <p class="card-text mb-0" *ngIf="item?.important">
                {{"general.important" | translate}}:
                <small class="text-muted">
                    {{item.important | translate}}
                </small>
            </p>

            <div class="d-flex justify-content-between">
                <span class="fs-4 fw-bolder">
                    {{"general.total" | translate}}: USD {{totales | number: '1.0-0'}}
                </span>
            </div>

        </div>
    </div>
</div>

<ng-template #renderRemoveBtn>
    <button type="button" class="btn-icon btn-icon-two">
        <i class="bi bi-trash3"></i>
    </button>
</ng-template>

<ng-template #renderLoader>
    <button type="button" class="btn-icon btn-icon-two">
        <div class="spinner-border spinner-border-sm" role="status">
            <span class="visually-hidden">
                {{"general.loading" | translate}}...
            </span>
        </div>
    </button>
</ng-template>