

<div class="modal fade modal-RoomTypeDetails" id="modalRoomTypeDetails" data-bs-backdrop="static" tabindex="-1" aria-labelledby="modalRoomTypeDetailsLabel" aria-hidden="true">
    <div class="modal-dialog modal-fullscreen">
        <div class="modal-content">

            <div class="modal-header justify-content-center">
                <h5 class="modal-title fw-bold text-uppercase">
                    {{ "general.changeRoomType" | translate | uppercase }}
                </h5>

            </div>

            <div class="modal-body">
                <div class="container">
                    <div class="row g-3">
                        <ng-container *ngIf="(list$ | async) as list; else loading">
                            <div class="col-12 col-md-6 mb-4" *ngFor="let row of list">
                                <div class="card card-box" >
                                    <div class="card-body">
                                        <h5 class="card-title fw-bolder">
                                            {{'hotel.ubitacion.'+row.ubicationType | translate | uppercase}}
                                            <!-- {{row.ubicationTypeDescription}} -->
                                        </h5>
                                        <p class="card-text">
                                            {{ 'hotel.roomType.'+row.roomCode | translate | uppercase}}
                                            <!-- {{row.description}} -->
                                        </p>
                                        <p class="card-text">
                                            <small class="text-muted">
                                                {{ "general.pricePerPerson" | translate }}: 
                                                <i class="bi" [ngClass]="{'bi-person-fill': row?.capacity == 1, 'bi-people-fill': row?.capacity > 1}"></i>
                                                USD {{ (row.price / row.capacity) | mask:'separator.0':',' }}
                                            </small>
                                        </p>
                                        <p class="card-text">
                                            {{ "general.valueWithoutDiscount" | translate }} <span class="fw-bold text-decoration-line-through">USD  {{row.fullPrice | mask:'separator.0':','}}</span>
                                        </p>
                                        <p class="card-text">
                                            {{ "general.amount" | translate }} <span class="fw-bold fs-4">USD  {{row.price | mask:'separator.0':','}}</span>
                                        </p>
                                        <div class="d-flex justify-content-end">
                                            <ng-container *ngIf="row.roomCode == item._id; else canUpdate">
                                                <a href="javascript:void(0)" class="btn btn-three btn-small disbled">
                                                    <i class="bi bi-house"></i>
                                                    {{ "general.current" | translate }} 
                                                    
                                                </a>
                                            </ng-container>
                                            
                                            <ng-template #canUpdate>
                                                <a href="javascript:void(0)" class="btn btn-one btn-small" (click)="onUpdateRoomDoc(row)">
                                                    <i class="bi bi-arrow-left-right"></i> 
                                                    
                                                    {{ "general.change" | translate }}
                                                </a>
                                            </ng-template>
                                        </div>
                                        

                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <div class="col-12">
                            <hr>
                        </div>
                        <div class="col-12 col-md-6 mb-2 mb-md-0">
                            <h5>
                                <span class="badge bg-danger">
                                    {{ "general.capacity" | translate }}: 
                                    <i class="bi" [ngClass]="{'bi-person-fill': item?.capacity == 1, 'bi-people-fill': item?.capacity > 1}"></i> {{ item?.capacity }}
                                </span>
                            </h5>
                        </div>
                        <div class="col-12 col-md-6 mb-2 mb-md-0">
                            <h5 class="text-start text-md-end">
                                Sub-Total:  <span class="fw-bold fs-2"> USD {{item?.price | mask:'separator.0':','}}</span> 
                            </h5>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer flex-column justify-content-center">
                <div class="w-50 d-flex flex-row justify-content-center align-items-center">
                    <button type="button" class="btn btn-one" (click)="closeModal()">
                        {{ "general.back" | translate }}
                    </button>
                </div>

            </div>
        </div>
    </div>
</div>

<ng-template #loading>
    <div class="col-12 col-md-6 mb-4" *ngFor="let item of [1,1,1,1]">
        <div class="card" >
            <div class="card-body">
                <h5 class="card-title fw-bolder placeholder-glow">
                    <span class="placeholder col-6" style="height: 16px;"></span>
                </h5>
                <p class="card-text placeholder-glow">
                    <span class="placeholder col-10" style="height: 16px;"></span>
                </p>
                <p class="card-text placeholder-glow">
                    <span class="placeholder col-8" style="height: 16px;"></span>
                </p>
                <p class="card-text placeholder-glow">
                    <span class="placeholder col-7" style="height: 16px;"></span>
                </p>
                <a href="#" tabindex="-1" class="btn btn-sm btn-danger disabled placeholder col-6"></a>
            </div>
        </div>
    </div>
</ng-template>