<div class="card card-box p-3 mb-3">
    <div class="row g-2 d-flex align-items-center">
        <div class="col-12 px-3">
            <div class="d-flex justify-content-between">
                <span class="text-rslt fw-bold fs-5">
                    {{ "general.room" | translate }} #{{index}}
                </span>
            </div>
        </div>
        <div class="seprd2"></div>
        <div class="col-12 px-3 mb-2">
            <!-- {{item?.ubicationTypeDescription | uppercase }} {{item?.description | uppercase}} -->
            {{'hotel.ubitacion.'+item.ubicationType | translate | uppercase}} - {{ 'hotel.roomType.'+item.roomCode | translate | uppercase}}
        </div>
        <div class="col-12 px-3">
            <p class="m-0">
                {{ "general.#ofGuests" | translate }} {{item.capacity}}

                <ng-container *ngIf="item.capacity == 0; else showPeople">
                    {{ "general.person" | translate | lowercase}}
                </ng-container>

                <ng-template #showPeople>
                    {{ "general.people" | translate | lowercase}}
                </ng-template>
            </p>
            <p class="m-0" *ngIf="nroAdditionalDays > 0">
                {{ "general.extraNight" | translate }} {{nroAdditionalDays}}
            </p>
            <hr>
            <p class="m-0">
                {{ "general.valueWithoutDiscount" | translate }} 
                <span class="text-decoration-line-through">
                    USD {{subTotalFullPrice | mask:'separator.0':','}}
                </span>
            </p>
            <p class="m-0">
                {{ "general.discount" | translate }}: USD {{discount | mask:'separator.0':','}}
            </p>
            <div class="d-flex justify-content-between">
                <span class="fs-4 fw-bolder">
                    {{ "general.total" | translate }}: USD {{total | mask:'separator.0':','}}
                </span>
                <div>
                    <button type="button" 
                        class="btn-icon btn-icon-one" 
                        (click)="onUpdate.next({index: index - 1, data: item})">
                        <i class="bi bi-pencil"></i>
                    </button>
                    &nbsp;
                    <button type="button" 
                        class="btn-icon btn-icon-two"
                        (click)="onRemove.next({index: index - 1, data: item})">
                        <i class="bi bi-trash3"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>