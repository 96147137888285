<div *ngIf="item">
    <div class="card">
        <div class="card-header">
            <span class="fw-bold text-rslt me-2 fs-4">
                {{ "category.register" | translate | titlecase }}
            </span>
        </div>
        <div class="card-body">
            <div class="row">

                <div class="col-12 col-md-6 col-xl-4">
                    <div class="item-info">
                        <span class="fw-bold me-2 fs-5">
                            {{ "category.division" | translate | titlecase }}:
                        </span>
                        <p>
                            {{ item.id| uppercase }}
                            {{ item.id| translate| uppercase }}
                        </p>
                    </div>
                </div>

                <div class="col-12 col-md-6 col-xl-4">
                    <div class="item-info">
                        <span class="fw-bold me-2 fs-5">
                            {{ "general.country" | translate | titlecase }}:
                        </span>
                        <p>
                            {{ item.representedCountry?.name | titlecase }} -
                            {{ item.representedState?.name | titlecase }} -
                            {{ item.representedCity?.name | titlecase }}
                        </p>
                    </div>
                </div>

                <div class="col-12 col-md-6 col-xl-4">
                    <div class="item-info">
                        <span class="fw-bold me-2 fs-5">
                            {{ "officialqualifiers" | translate | titlecase }}
                        </span>
                        <p>
                            {{ (item.officialQualifiers?.name || "N/A") | titlecase }} - {{ (item.achievedPosition?.name
                            || "N/A") }}
                        </p>
                    </div>
                </div>

                <div class="col-12 col-md-6 col-xl-4">
                    <div class="item-info">
                        <span class="fw-bold me-2 fs-5">
                            {{ "general.firstPlaceWon" | translate | titlecase }}:
                        </span>
                        <p>
                            {{ (item.firstPlaceWon?.name || "N/A") | titlecase }}
                        </p>
                    </div>
                </div>

                <div class="col-12 col-md-6 col-xl-4">
                    <div class="item-info">
                        <span class="fw-bold me-2 fs-5">
                            {{ "general.school" | translate }}:
                        </span>
                        <p>
                            {{ (item.schoolThatRepresents?.name || 'N/A') | titlecase }}
                        </p>
                    </div>
                </div>

                <div class="col-12 col-md-6 col-xl-4">
                    <div class="item-info">
                        <span class="fw-bold me-2 fs-5">
                            Instagram:
                        </span>
                        <p>
                            {{ (item?.instagram || 'N/A') }}
                        </p>
                    </div>
                </div>

                <div class="col-12 col-md-6 col-xl-4">
                    <div class="item-info">
                        <span class="fw-bold me-2 fs-5"> Facebook: </span>
                        <p>
                            {{ (item?.facebook|| 'N/A') }}
                        </p>
                    </div>
                </div>

                <div class="col-12 col-md-6 col-xl-4">
                    <div class="item-info">
                        <span class="fw-bold me-2 fs-5">
                            {{ "category.creatorOfTheChoreography" | translate | titlecase}}:
                        </span>
                        <p>
                            {{ (item?.choreographyCreator || 'N/A') | profile | async | titlecase}}
                        </p>
                    </div>
                </div>

                <div class="col-12 col-md-6 col-xl-4">
                    <div class="item-info">
                        <span class="fw-bold me-2 fs-5">
                            {{ "category.trainersName" | translate | titlecase }}:
                        </span>
                        <p>
                            {{ (item?.nameCoach || 'N/A') | profile | async | titlecase }}
                        </p>
                    </div>
                </div>


                <div *ngIf="item.photo" class="col-12 col-md-6 col-xl-4">
                    <div class="item-info">
                        <span class="fw-bold me-2 fs-5">
                            {{ "category.photo" | translate | titlecase }}:
                        </span>
                        <img width="100" height="100" [src]="item?.photo" alt="">
                    </div>
                </div>

                <div class="col-12 col-md-6 col-xl-6"></div>
                <div class="col-12 col-md-6 col-xl-6"></div>
            </div>

            <ng-container *ngIf="item.participants?.length > 0">
                <div class="fw-bold mt-3 mb-2">{{"general.participants" | translate}}</div>
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">{{ "name" | translate | titlecase }}</th>
                                <th scope="col">{{ "email" | translate | titlecase }}</th>
                                <th scope="col">
                                    {{ "document" | translate | titlecase }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let user of item.participants; let i = index">
                                <th scope="row">{{ i + 1 }}</th>
                                <td>{{ user.name | titlecase }}</td>
                                <td>{{ user.email }}</td>
                                <td>{{ user.identification }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </ng-container>

            <div *ngIf="item.music" class="d-flex justify-content-center">
                <audio [src]="item.music" controls></audio>
            </div>

            <div class="card-box p-2 mt-2" *ngIf="item.users?.length === 0">
                {{ "general.noRegisteredUsers" | translate }}.
            </div>
        </div>

        <div class="card-footer text-body-secondary">
            {{ "general.note" | translate }}:
            {{ "category.registerConfirmed" | translate }}
        </div>
    </div>
</div>