<ng-container *ngIf="(document$ | async) as document; else loading">
    <div class="col-12 mb-4">
        <div class="card card-box p-3 mb-3">
            <div class="row g-2 d-flex align-items-center">
                <div class="col-12 px-3">
                    <div class="d-flex justify-content-between">
                        <span class="fw-bold fs-5">
                            {{'general.groupCategory' | translate}}
                        </span>
        
                        <span>
                            {{'general.pricePerPerson' | translate}}: USD {{price | mask:'separator.0':','}}
                        </span>
                    </div>
                </div>
                <div class="col-12 m-0 px-3">
                    <hr>
                </div>
    
                <div class="col-12" *ngFor="let item of groups; index as idx">
                    <div class="col-12 d-flex justify-content-between">
                        <span class="fw-bold fs-5">
                            {{'general.group' | translate | titlecase}} #{{idx + 1}}
                        </span>

                        <span>
                            {{'general.pricePerPerson' | translate}}: USD {{item.pricePerPeople | mask:'separator.0':','}}
                            &nbsp;
                            <span class="btn-close-card-item"><i class="bi bi-x-lg" (click)="removeItem(idx)"></i></span>
                        </span>
                    </div>
                    <div class="row p-2 align-items-stretch">
                        <div class="col-12 col-sm-6 col-md-6">
                            <app-input-number-form-two
                                [label]="'# ' + ('general.#membersOfGroup' | translate)" 
                                [min]="3" [max]="99" [quantity]="item.quantity"
                                (onUpdateQuantity)="updateQuantity({index: idx, quantity: $event, data: document})"></app-input-number-form-two>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 d-flex justify-content-end align-self-end mt-3 mt-md-0">
                            <div class="card-box card-box-wfc p-3">
                                <h4 class="text-end py-2 m-0">
                                    {{'general.amount' | translate | titlecase}} USD {{item.price * item.quantity | mask:'separator.0':','}}
                                </h4>                                
                            </div>
                        </div>
                    </div>
                    <div class="seprd"></div>
                </div>
    
                <div class="col-12">
                    <button type="button" class="btn btn-one" (click)="addGroup(document)">
                        {{'general.addGroup' | translate | uppercase}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<ng-template #loading>
    <div class="col-12 mb-4">
        <div class="card card-box p-3 mb-3">
            <div class="row g-2">
                <div class="col-12">
                    <div class="placeholder-glow">
                        <span class="placeholder col-12" style="height: 36px;"></span>
                    </div>
                </div>
                <div class="col-12 m-0 px-3">
                    <hr>
                </div>
    
                <div class="col-12">
                    <a href="#" tabindex="-1" role="button" class="btn btn-one disabled placeholder col-6"></a>
                </div>
            </div>
        </div>
    </div>
</ng-template>


<ng-template #noGroups>
    <div class="col-12">

    </div>
</ng-template>