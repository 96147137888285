<form [formGroup]="form" (ngSubmit)="onSubmit()" novalidate>
    <div class="input-group mb-3">
        <input type="text" class="form-control" formControlName="code"
            placeholder="{{ 'general.couponCode' | translate }}"
            [attr.readOnly]="(showLoadingBtn === false) ? null : true">
        <ng-container *ngIf="(!showLoadingBtn); then renderApplyBtn; else renderLoaderBtn"></ng-container>
    </div>
    <div class="text-center">
        <ng-container *ngIf="submitted" [ngTemplateOutlet]="renderFormVM"
            [ngTemplateOutletContext]="{field: 'code'}"></ng-container>
    </div>
</form>


<ng-template #renderApplyBtn>
    <button *ngIf="!isButtonDisabled" class="btn btn-sm btn-danger" id="button-apply-coupon" type="submit">
        {{'general.apply' | translate}}
    </button>

    <button *ngIf="isButtonDisabled" class="btn btn-sm btn-danger" id="button-apply-coupon" (click)="removeCupon()">
        {{'general.remove' | translate}}
    </button>
</ng-template>

<ng-template #renderLoaderBtn>
    <button class="btn btn-sm btn-danger" id="button-apply-coupon" type="button" disabled>
        <div class="text-center">
            <div class="spinner-border spinner-border-sm" role="status">
                <span class="visually-hidden">
                    {{"general.loading" | translate}}...
                </span>
            </div>
        </div>
    </button>
</ng-template>

<ng-template #renderFormVM let-field="field">
    <ng-container *ngFor="let item of vm[field]">
        <span class="badge bg-danger" *ngIf="f[field].hasError(item.type)">
            {{ item.message | translate }}
        </span>
    </ng-container>
</ng-template>