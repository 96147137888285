<div class="card card-box">
    <div class="card-body">

        <div class="d-flex justify-content-between pb-2">
            <div class="d-flex align-items-center text-rslt fw-bold fs-6">
                <ng-container [ngTemplateOutlet]="renderCapacityIcon"></ng-container>
                | {{item?.title | translate }}
            </div>

            <div class="c-icon d-flex align-items-center">
                <img src="assets/img/icon-bed-gray.png" alt="">
                <img src="assets/img/icon-ticket-gray.png" alt="">
            </div>
        </div>

        <div class="seprd2"></div>

        <div class="card-plan mb-1">
            <div class="row align-items-center">
                <div class="col-12 col-md-6 mt-3">
                    <div class="fs-3 text-rslt fw-bold lh-1">
                        {{item.room.locationLabel | translate}}
                    </div>
                    <div class="fs-3 mb-3 fw-bold lh-1">
                        {{item.room.title | translate}}
                    </div>
                    <div class="fw-bold">
                        <span class="fw-bold">
                            {{"general.checkInDate" | translate}}:
                        </span>
                        <span class="text-rslt">
                            <ng-container *ngIf="checkIn" [ngTemplateOutlet]="renderDateParsed"
                                [ngTemplateOutletContext]="{date: checkIn}"></ng-container>
                        </span>
                    </div>
                    <div class="fw-bold">
                        <span class="fw-bold">
                            {{"general.checkOutDate" | translate}}:
                        </span>
                        <span class="text-rslt">
                            <ng-container *ngIf="checkIn" [ngTemplateOutlet]="renderDateParsed"
                                [ngTemplateOutletContext]="{date: checkOut}"></ng-container>
                        </span>
                    </div>
                    <div>
                        {{"general.totalNights" | translate}}: {{nroNights}}
                    </div>
                    <!-- <div>Cantidad de habitaciones: 1</div> -->
                    <div>
                        {{"general.amountOfPeople" | translate}}:
                        {{item.room.capacity}} {{"general." + ((item.room.capacity == 1) ? 'adult' : 'adults') |
                        translate}}
                    </div>

                    <div class="c-icon my-2">
                        {{item.room.nroBeds}}

                        <!-- camas dobles  -->
                        <ng-container *ngFor="let item of item.room.bedsLabel; last as isLast">
                            {{item | translate}}
                            <ng-container *ngIf="(!isLast)">
                                {{'general.or' | translate}}&nbsp;
                            </ng-container>
                        </ng-container>

                        <img src="assets/img/icon-bed-gray.png" alt="">
                    </div>
                </div>
                <div class="col-12 col-md-6 d-flex justify-content-center  justify-content-md-end">
                    <div class="img-card">
                        <!-- <img src="assets/img/hotel.jpg" alt=""> -->
                        <img src="assets/images/rooms/{{item.room.subcode}}.png" alt="">
                    </div>
                </div>
                <div class="col-12">
                    <p class="card-text mb-0">
                        {{ "general.include" | translate | titlecase}}:
                        <small class="text-muted">
                            {{item.room.include | translate}}
                        </small>
                    </p>
                    <p class="card-text">
                        {{ "general.notInclude" | translate | titlecase}}:
                        <small class="text-muted">
                            {{item.room.notInclude | translate}}
                        </small>
                    </p>
                </div>
            </div>
        </div>

        <div class="d-md-flex align-content-center justify-content-center justify-content-md-between">
            <div class="d-flex justify-content-center align-items-md-center mb-2 mb-md-0">
                <span class="fw-bold fs-4">
                    {{"general.total" | translate}}: USD {{totales | number: '1.0-0'}}
                </span>
            </div>
        </div>

    </div>
</div>

<ng-template #renderDateParsed let-date="date">
    {{date | date: 'd MMM yyyy'}}
</ng-template>

<ng-template #renderCapacityIcon>
    <i class="bi" [ngClass]="{'bi-person-fill': item.room.capacity == 1, 'bi-people-fill': item.room.capacity > 1}"></i>
    {{item.room.capacity}}
</ng-template>