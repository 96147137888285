<div class="modal fade modalTCcategoriasAdd" id="modalTCcategoriasA" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="modalTCcategoriasALabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-body">
                <div class="container">
                    <div class="row g-3">
                        <div class="col-12">
                            <div class="logo mb-3">
                                <img src="assets/img/cilik/logo.png" alt="">
                            </div>
                            <h5 class="text-center fw-bolder mb-4">
                                {{'general.termsAndConditions' | translate | titlecase}} - {{'general.additionalCategories' | translate | titlecase}}
                            </h5>
                            <ul>
                                <li>
                                    <span class="fw-bold">
                                        {{('additionalsCategoriesStep.termsAndConditions' | translate)[0]}}
                                    </span>
                                    <br>
                                    {{('additionalsCategoriesStep.termsAndConditions' | translate)[1]}}
                                </li>
                                <li>
                                    {{('additionalsCategoriesStep.termsAndConditions' | translate)[2]}}
                                </li>
                                <li>
                                    {{('additionalsCategoriesStep.termsAndConditions' | translate)[3]}}
                                </li>
                                <li>
                                    {{('additionalsCategoriesStep.termsAndConditions' | translate)[4]}}
                                </li>
                                <li>
                                    {{('additionalsCategoriesStep.termsAndConditions' | translate)[5]}}
                                </li>
                            </ul>
                        </div>

                    </div>
                </div>
            </div>
            <div class="modal-footer d-flex justify-content-center align-items-center justify-content-lg-around flex-wrap">
                <!-- <button type="button" class="btn btn-three p-2" 
                    (click)="closeModal(false)"
                    [innerHTML]="'additionalsCategoriesStep.modalDeclineTerms' | translate">
                    
                </button> -->
                <button 
                    class="btn btn-three btn-medium-c text-uppercase" 
                    type="button"
                    (click)="closeModal(false)"
                >
                    <i class="bi bi-chevron-left"></i>
                    {{ "general.back" | translate }}
                </button>
                <!-- <button type="button" class="btn btn-one p-2" 
                    (click)="closeModal(true)" 
                    [innerHTML]="'additionalsCategoriesStep.modalConfirmTerms' | translate">
                </button> -->
                <button 
                    type="button" 
                    class="btn btn-one text-uppercase" 
                    (click)="closeModal(true)" 
                    [innerHTML]="'general.acquireAdditionalCategories' | translate"
                >
                </button>
            </div>
        </div>
    </div>
</div>