import { Injectable } from '@angular/core';
import { EventInfoService } from './event-info.service';

@Injectable({
  providedIn: 'root'
})
export class PassesService {

  public passPrice = {
    'full-pass': [
      {
        ranges: { from: '2023-08-01', to: '2023-11-16' },
        price: 139
      },
      {
        ranges: { from: '2023-11-17', to: '2023-12-16' },
        price: 139
      },
      {
        ranges: { from: '2023-12-17', to: '2024-02-28' },
        price: 159
      },
    ],
    'weekend-fest': [
      {
        ranges: { from: '2023-11-17', to: '2023-12-16' },
        soloist: 348,
        couples: 205,
        groups: 188
      },
      {
        ranges: { from: '2023-12-17', to: '2024-02-28' },
        soloist: 423,
        couples: 303,
        groups: 280
      },
    ],
    'weekend-pass': [
      {
        ranges: { from: '2023-08-01', to: '2023-11-16' },
        price: 89
      },
      {
        ranges: { from: '2023-11-17', to: '2023-12-16' },
        price: 89
      },
      {
        ranges: { from: '2023-12-17', to: '2024-02-28' },
        price: 119
      },
    ],
    'category-pass': [
      {
        ranges: { from: '2023-08-01', to: '2023-11-16' },
        soloist: 50,
        couples: 35,
        groups: 15
      },
      {
        ranges: { from: '2023-11-17', to: '2023-12-16' },
        soloist: 50,
        couples: 35,
        groups: 15
      },
      {
        ranges: { from: '2023-12-17', to: '2024-02-28' },
        soloist: 60,
        couples: 45,
        groups: 20
      },
    ],
    'full-pass-per-day': [
      {
        ranges: { from: '2023-08-01', to: '2023-12-16' },
        wednesdayPass: 15,
        thursdayPass: 25,
        fridayPass: 30,
        saturdayPass: 50,
        sundayPass: 30
      },
      {
        ranges: { from: '2023-12-17', to: '2024-20-01' },
        wednesdayPass: 18,
        thursdayPass: 30,
        fridayPass: 35,
        saturdayPass: 55,
        sundayPass: 35
      },
    ],
    'day-pass': [
      {
        ranges: { from: '2023-08-01', to: '2023-11-16' },
        dayOfWeek: [30, 15, 15, 15, 25, 30, 50]
      },
      {
        ranges: { from: '2023-11-17', to: '2023-12-16' },
        dayOfWeek: [35, 18, 18, 18, 30, 35, 55]
      },
      {
        ranges: { from: '2023-12-17', to: '2024-02-28' },
        dayOfWeek: [40, 20, 20, 20, 35, 40, 60]
      },
    ],

  };

  constructor(
    private eventInfoSrv: EventInfoService,
  ) { }

  getPassPriceBySlug(slug: string) {
    return this.passPrice[slug];
  }

  getPassPriceByDateAndSlug(date: string, slug: string) {
    const priceList = this.getPassPriceBySlug(slug);
    const price = priceList.find((item: any) => {
      return this.eventInfoSrv.isDateInRange(date, item.ranges);
    });
    return price;
  }
}
