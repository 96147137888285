<!-- <form *ngIf="tuCompraObject" method="POST" id="tuCompraPresale" [action]="tuCompraObject.url">
    <input name="factura" [(ngModel)]="tuCompraObject.factura" id="factura" type="hidden">
    <input name="valor" [(ngModel)]="tuCompraObject.valor" id="valor" type="hidden">
    <input name="descripcionFactura" [(ngModel)]="tuCompraObject.descripcionFactura" id="descripcionFactura"
        type="hidden">
    <input name="documentoComprador" [(ngModel)]="tuCompraObject.documentoComprador" id="documentoComprador"
        type="hidden">
    <input name="tipoDocumento" [(ngModel)]="tuCompraObject.tipoDocumento" id="tipoDocumento" type="hidden">
    <input name="nombreComprador" [(ngModel)]="tuCompraObject.nombreComprador" id="nombreComprador" type="hidden">
    <input name="apellidoComprador" [(ngModel)]="tuCompraObject.apellidoComprador" id="apellidoComprador" type="hidden">
    <input name="correoComprador" [(ngModel)]="tuCompraObject.correoComprador" id="correoComprador" type="hidden">
    <input name="celularComprador" [(ngModel)]="tuCompraObject.celularComprador" id="celularComprador" type="hidden">
    <input name="telefonoComprador" [(ngModel)]="tuCompraObject.telefonoComprador" id="celularComprador" type="hidden">
    <input name="paisComprador" [(ngModel)]="tuCompraObject.paisComprador" id="paisComprador" type="hidden">
    <input name="ciudadComprador" [(ngModel)]="tuCompraObject.ciudadComprador" id="ciudadComprador" type="hidden">
    <input name="direccionComprador" [(ngModel)]="tuCompraObject.direccionComprador" id="direccionComprador"
        type="hidden">
    <input name="tipoMoneda" [(ngModel)]="tuCompraObject.tipoMoneda" id="tipoMoneda" type="hidden">
    <input name="campoExtra1" [(ngModel)]="tuCompraObject.campoExtra1" id="campoExtra1" type="hidden">
    <input name="campoExtra2" [(ngModel)]="tuCompraObject.campoExtra2" id="campoExtra2" type="hidden">
    <input name="campoExtra3" [(ngModel)]="tuCompraObject.campoExtra3" id="campoExtra3" type="hidden">
    <input name="usuario" type="hidden" [(ngModel)]="tuCompraObject.usuario">
</form>

<button class="btn btn-danger" (click)="payWithTuCompra()">
    Comprar con tuCompra
</button> -->

<form class="mb-5" [formGroup]="form" (ngSubmit)="onSubmit()" #userForm novalidate>
    <div class="mb-3">
        <label for="documentoComprador" class="form-label">Nro. Documento</label>
        <input type="text" class="form-control" formControlName="documentoComprador" aria-describedby="documentoCompradorHelp">
        <ng-container 
            *ngIf="submitted" 
            [ngTemplateOutlet]="renderFormVM" 
            [ngTemplateOutletContext]="{ field: 'documentoComprador' }"
        ></ng-container>
    </div>

    <div class="mb-3">
        <label for="tipoDocumento" class="form-label">Tipo Documento</label>
        <select class="form-select" formControlName="tipoDocumento" placeholder="Tipo Documento">
            <option value="" selected>Seleccionar...</option>
            <option value="CC">cedula ciudadanía</option>
            <option value="CE">cedula extranjería</option>
            <option value="NIT">número identificación tributaria</option>
            <option value="PAS">pasaporte</option>
        </select>
        <ng-container 
            *ngIf="submitted" 
            [ngTemplateOutlet]="renderFormVM" 
            [ngTemplateOutletContext]="{ field: 'tipoDocumento' }"
        ></ng-container>
    </div>

    <div class="mb-3">
        <label for="nombreComprador" class="form-label">Nombres</label>
        <input type="text" class="form-control" formControlName="nombreComprador" aria-describedby="nombreCompradorHelp">
        <ng-container 
            *ngIf="submitted" 
            [ngTemplateOutlet]="renderFormVM" 
            [ngTemplateOutletContext]="{ field: 'nombreComprador' }"
        ></ng-container>
    </div>

    <div class="mb-3">
        <label for="apellidoComprador" class="form-label">Apellidos</label>
        <input type="text" class="form-control" formControlName="apellidoComprador" aria-describedby="apellidoCompradorHelp">
        <ng-container 
            *ngIf="submitted" 
            [ngTemplateOutlet]="renderFormVM" 
            [ngTemplateOutletContext]="{ field: 'apellidoComprador' }"
        ></ng-container>
    </div>

    <div class="mb-3">
        <label for="correoComprador" class="form-label">Email</label>
        <input type="text" class="form-control" formControlName="correoComprador" aria-describedby="correoCompradorHelp">
        <ng-container 
            *ngIf="submitted" 
            [ngTemplateOutlet]="renderFormVM" 
            [ngTemplateOutletContext]="{ field: 'correoComprador' }"
        ></ng-container>
    </div>

    <div class="mb-3">
        <label for="celularComprador" class="form-label">Celular</label>
        <input type="text" class="form-control" formControlName="celularComprador" aria-describedby="celularCompradorHelp">
        <ng-container 
            *ngIf="submitted" 
            [ngTemplateOutlet]="renderFormVM" 
            [ngTemplateOutletContext]="{ field: 'celularComprador' }"
        ></ng-container>
    </div>

    <div class="mb-3">
        <label for="direccionComprador" class="form-label">Dirección</label>
        <input type="text" class="form-control" formControlName="direccionComprador" aria-describedby="direccionCompradorHelp">
        <ng-container 
            *ngIf="submitted" 
            [ngTemplateOutlet]="renderFormVM" 
            [ngTemplateOutletContext]="{ field: 'direccionComprador' }"
        ></ng-container>
    </div>

    <button type="submit" class="btn btn-danger">
        Pagar con tuCompra
    </button>
</form>

<ng-template #renderFormVM let-field="field">
    <ng-container *ngFor="let val of vm[field]">
        <div id="{{field}}Help" class="form-text text-danger" *ngIf="f[field].hasError(val.type)">
            {{ val.message | translate | titlecase }}
        </div>
    </ng-container>
</ng-template>


<!-- <ng-container *ngIf="(payConfig); then renderPayButton; else renderOutButton"></ng-container>

<ng-template #renderPayButton>
    <button class="btn btn-danger" (click)="payWithTuCompra()">
        Comprar con tuCompra
    </button>
</ng-template>

<ng-template #renderOutButton>
    <button class="btn btn-danger">
        Configura tuCompra
    </button>
</ng-template> -->