<ng-container *ngIf="orderType == 'fullPass'">
    <div class="card card-box p-3 mt-3 red-card">
        <div class="row g-2 d-flex align-items-center">
            <div class="col-12 px-3">
                <div class="d-flex justify-content-between align-items-center">
                    <span class=" fw-bold fs-5">
                        <app-purchase-title-card-item [orderType]="orderType"></app-purchase-title-card-item>
                    </span>

                    <img
                    src="assets/images/icons/plan-icon-1.png"
                    class="img-fluid"
                    alt=""
                />
                </div>
            </div>
            <div class="seprd2"></div>
            <div class="col-12 px-3">
                <p class="m-0">
                    {{'plansCard.include' | translate}}: {{'plansCard.allInclusivePlan.include' | translate}}
                </p>
            </div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="orderType == 'hotelAndEventPass'">
    <div class="card card-box p-3 mt-3 purple-card">
        <div class="row g-2 d-flex align-items-center">
            <div class="col-12 px-3">
                <div class="d-flex justify-content-between align-items-center">
                    <span class=" fw-bold fs-5 text-uppercase">
                        <app-purchase-title-card-item [orderType]="orderType"></app-purchase-title-card-item>
                    </span>


                    <img
                    src="assets/images/icons/plan-icon-2.png"
                    class="img-fluid"
                    alt=""
                />
                </div>
            </div>
            <div class="seprd2"></div>
            <div class="col-12 px-3">
                <p class="m-0">
                    <span class="bold text-uppercase">
                        {{'plansCard.include' | translate}}:
                    </span>  
                    {{'plansCard.hotelPlanAndFullPass.include' | translate}}
                    <span class="bold text-uppercase">    
                        {{'plansCard.notInclude' | translate}}:
                    </span> 
                    {{'plansCard.hotelPlanAndFullPass.noInclude' | translate}}
                </p>
            </div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="orderType == 'hotelPass'">
    <div class="card card-box p-3 mt-3 green-card">
        <div class="row g-2 d-flex align-items-center">
            <div class="col-12 px-3">
                <div class="d-flex justify-content-between align-items-center">
                    <span class=" fw-bold fs-5">
                        <app-purchase-title-card-item [orderType]="orderType"></app-purchase-title-card-item>
                    </span>

                    <img
                    src="assets/images/icons/plan-icon-3.png"
                    class="img-fluid plan-icon-3"
                    alt=""
                />
                </div>
            </div>
            <div class="seprd2"></div>
            <div class="col-12 px-3">
                <p class="m-0">
                    <span class="bold text-uppercase">
                        {{'plansCard.include' | translate}}:
                    </span> 
                    {{'plansCard.hotelOnlyPlan.include' | translate}}
                    <span class="bold text-uppercase">                    
                        {{'plansCard.notInclude' | translate}}:
                    </span>
                    {{'plansCard.hotelOnlyPlan.noInclude' | translate}}
                </p>
            </div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="orderType == 'eventPass'">
    <div class="card card-box p-3 mt-3 yellow-card">
        <div class="row g-2 d-flex align-items-center">
            <div class="col-12 px-3">
                <div class="d-flex justify-content-between align-items-center">
                    <span class=" fw-bold fs-5">
                        <app-purchase-title-card-item [orderType]="orderType"></app-purchase-title-card-item>
                    </span>
                    <img
                    src="assets/images/icons/plan-icon-4.png"
                    class="img-fluid plan-icon-4"
                    alt=""
                />
                </div>
            </div>
            <div class="seprd2"></div>
            <div class="col-12 px-3">
                <p class="m-0">
                    <span class="bold text-uppercase">
                        {{'plansCard.include' | translate}}:
                    </span> 
                    {{'plansCard.fullPass.include' | translate}}
                    <br>
                    <span class="bold text-uppercase">                    
                        {{'plansCard.notInclude' | translate}}:
                    </span>
                    {{'plansCard.fullPass.noInclude' | translate}}
                </p>
            </div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="orderType == 'categoryPass'">
    <div class="card card-box p-3 mt-3 blue-card">
        <div class="row g-2 d-flex align-items-center">
            <div class="col-12 px-3">
                <div class="d-flex justify-content-between align-items-center">
                    <span class="fw-bold fs-5">
                        <app-purchase-title-card-item [orderType]="orderType"></app-purchase-title-card-item>
                    </span>

                    <img
                    src="assets/images/icons/plan-icon-5.png"
                    class="img-fluid plan-icon-3"
                    alt=""
                />
                </div>
            </div>
            <div class="seprd2"></div>
            <div class="col-12 px-3">
                <p class="m-0">
                    <span class="bold text-uppercase">                    
                        {{'plansCard.notInclude' | translate}}:
                    </span>
                    {{'plansCard.categoriesAdditional.noInclude' | translate}}
                </p>
            </div>
        </div>
    </div>
</ng-container>

