<ng-container *ngIf="globalTotal.globalDiscount > 0 || globalTotal.globalDiscountPercentage > 0">
    <h6 class="text-end">
        <span class="fw-bold me-2">{{ "general.valueWithoutDiscount" | translate }}:</span>
        <span class="text-decoration-line-through">USD {{globalTotal.globalSubtotal | currency}}</span>
    </h6>

    <h6 *ngIf="globalTotal.globalDiscount > 0" class="text-end">
        <span class="badge rounded-pill bg-danger">
            <i class="bi bi-info-circle"></i>
        </span>
        &nbsp;
        <span class="fw-bold me-2">{{ "general.discount" | translate }}:</span>
        <span>USD {{globalTotal.globalDiscount | currency}}</span>
    </h6>

    <h6 *ngIf="globalTotal.globalDiscountPercentage > 0" class="text-end">
        <span class="badge rounded-pill bg-danger">
            <i class="bi bi-info-circle"></i>
        </span>
        &nbsp;
        <span class="fw-bold me-2">{{ "general.discount" | translate }}:</span>
        <span>USD {{globalTotal.globalDiscountPercentage | currency}}</span>
    </h6>

</ng-container>



<h5 class="fw-bold fs-3 text-end">
    {{ "general.total" | translate }}: USD {{globalTotal?.globalTotalToPay | currency}}
</h5>
