<div>
    <webcam [height]="500" [width]="500" [trigger]="triggerObservable" (imageCapture)="handleImage($event)"
        *ngIf="showWebcam" [allowCameraSwitch]="allowCameraSwitch" [switchCamera]="nextWebcamObservable"
        [videoOptions]="videoOptions" [imageQuality]="1" (cameraSwitched)="cameraWasSwitched($event)"
        (initError)="handleInitError($event)" class="img-thumbnail"></webcam>
    <br>
    <button class="btn btn-primary actionBtn" (click)="triggerSnapshot();">Take A Snapshot</button>
    <button class="btn btn-secondary actionBtn" (click)="toggleWebcam();">Toggle Webcam</button>
    <br>
    <!-- <button class="btn btn-info actionBtn" (click)="showNextWebcam(true);" [disabled]="!multipleWebcamsAvailable">Next
        Webcam</button>
    <div class="form-check form-switch">
        <input id="cameraSwitchCheckbox" class="form-check-input" type="checkbox" [(ngModel)]="allowCameraSwitch">
        <label class="form-check-label" for="cameraSwitchCheckbox">Allow Camera Switch</label>
    </div>
    <br>
    <div>
        Preferred facing mode:
        <div class="form-check">
            <input class="form-check-input" type="radio" value="user" name="facingMode" [(ngModel)]="facingMode"
                id="userMode">
            <label class="form-check-label" for="userMode">User</label>
        </div>
        <div class="form-check">
            <input class="form-check-input" type="radio" value="environment" name="facingMode" [(ngModel)]="facingMode"
                id="environmentMode">
            <label class="form-check-label" for="environmentMode">Environment</label>
        </div>
        <div class="form-check">
            <input class="form-check-input" type="radio" value="" name="facingMode" [(ngModel)]="facingMode"
                id="noPreference">
            <label class="form-check-label" for="noPreference">No preference</label>
        </div>
    </div> -->
</div>

<div class="snapshot card mt-3" *ngIf="webcamImage">
    <h2 class="card-header">Nice one!</h2>
    <img [src]="webcamImage.imageAsDataUrl" alt="webcam image" class="card-img-bottom">
    <br>

    <button class="btn btn-primary actionBtn" (click)="savePhoto();">Take A Snapshot</button>

</div>

<!-- <h4 *ngIf="messages.length > 0">Messages:</h4>
<ul class="list-group" *ngFor="let msg of messages">
    <li class="list-group-item">{{msg | json}}</li>
</ul> -->