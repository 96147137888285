<button 
    type="button" 
    class="btn btn-one" 
    (click)="addRoom(capacity)" 
    [disabled]="disabled" 
    *ngIf="!loading"
>
    {{ "general.addRoom" | translate | uppercase}}
</button>

<button type="button" class="btn btn-one" disabled *ngIf="loading">
    <div class="d-flex justify-content-center">
        <div class="spinner-border spinner-border-sm" role="status">
          <span class="visually-hidden">{{ "general.loading" | translate }}...</span>
        </div>
        &nbsp; {{ "general.loading" | translate }}...
    </div>
</button>