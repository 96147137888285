<div class="row mb-4">
    <div class="col-12 mb-4">
        <p>
            Para tu comodidad, te ofrecemos las siguientes opciones bancarias para realizar transferencias:
        </p>
        <ul>
            <li> <strong>Bancolombia</strong>: Una opción confiable con amplia cobertura en Colombia, ideal si buscas
                una
                transferencia rápida y eficiente.</li>
            <li>
                <strong>ADCB (Abu Dhabi Commercial Bank)</strong>:Si buscas una solución internacional, esta es tu
                opción ideal.
            </li>
        </ul>

        <p>Elige la entidad que se ajuste mejor a tus necesidades y sigue las instrucciones para completar tu
            transferencia. Si tienes alguna duda o inquietud, no dudes en contactarnos.

        </p>
    </div>

    <div class="col-12 mb-4">
        <div class="row d-flex justify-content-center">
            <div class="col-12 col-sm-8 col-md-6 mb-4">
                <div class="d-flex flex-column align-items-center justify-content-center">
                    <ng-container *ngFor="let item of bankOptions">
                        <button class="btn btn-payment btn-lg mb-3"
                            [ngClass]="{'active': optionSelected && item.slug == optionSelected.slug}" type="button"
                            (click)="selectOption(item)">
                            <span class="icon-payment"><i class="bi bi-bank"></i>&nbsp;</span>
                            <span class="label-payment">
                                {{item.label}}
                            </span>
                        </button>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>

    <div class="col-12 text-center">
        <button type="button" class="btn" [ngClass]="{'btn-three': !optionSelected, 'btn-danger': optionSelected}"
            [attr.disabled]="(!optionSelected) ? true : null" (click)="confirmOption()">
            <i class="bi bi-check2-square"></i>
            &nbsp;
            {{"general.confirm" | translate | titlecase}}
        </button>
    </div>
</div>