import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-permission-roles-update',
  templateUrl: './permission-roles-update.component.html',
  styleUrls: ['./permission-roles-update.component.css']
})
export class PermissionRolesUpdateComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
