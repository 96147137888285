<div class="container">
    <div class="row">
        <div class="col-12 col-sm-12 col-md-10 col-lg-6 mx-auto">
            <div class="card border-0 p-5 my-5">


                <app-header-auth class="mb-3"></app-header-auth>


                <div class="logo d-flex justify-content-center">
                    <img src="assets/img/cilik/logo.png" alt="">
                </div>
                <router-outlet></router-outlet>
                <app-footer-cilik></app-footer-cilik>
            </div>
        </div>
    </div>
</div>