<div class="container vh-100">
    <div class="row row h-100 justify-content-center align-items-center">
      <div class="col-sm-9 col-md-7 col-lg-5 mx-auto">
        <div class="card border-0 rounded-3 my-5">
          <app-language-bar></app-language-bar>
          
          <div class="p-4 p-sm-5">
            <h1 class="card-title text-center fw-bold fs-2">
              <b>{{ "general.successfulRegistration" | translate }}</b>
            </h1>
            <form>
                <div class="text-center mb-4">
                  {{ "signUp.successfulRegistrationMessage" | translate }}
                </div>
                <div class="d-flex justify-content-center">
                    <a class="btn btn-one btn-small text-uppercase fw-bold" [routerLink]="['/pre-sale', 'step1']" role="button">
                      {{ "general.continue" | translate }}
                    </a>
                </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>