import { Component, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import {
  ScannerQRCodeConfig,
  ScannerQRCodeResult,
  NgxScannerQrcodeService,
  NgxScannerQrcodeComponent,
  ScannerQRCodeSelectedFiles,
} from 'ngx-scanner-qrcode';
@Component({
  selector: 'app-scanner-qrcode',
  templateUrl: './scanner-qrcode.component.html',
  styleUrls: ['./scanner-qrcode.component.css']
})
export class ScannerQrcodeComponent implements OnInit {

  @Output() onSelectFile = new Subject<any>();


  constructor() { }

  ngOnInit(): void {
  }


  public async onEvent(e: ScannerQRCodeResult[], action?: any): Promise<void> {
    this.onSelectFile.next(e[0].value);
    action.pause();

  }

}
