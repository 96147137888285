<div class="modal fade" [id]="_id" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-fullscreen">
        <div class="modal-content">

            <div class="modal-header">
                <button type="button" class="btn-close" aria-label="Close" (click)="closeModal()"></button>
            </div>
            <div class="modal-body">
                <div class="container">

                    <div class="row">

                        <div class="col-12">
                            <div class="fs-4 text-rslt fw-bold mb-2">
                                {{'Filter ADD User' | translate | titlecase}}
                            </div>
                        </div>

                        <div class="col-12">
                            <div class="card card-box p-3">
                                <div class="row g-2 d-flex align-items-center">

                                    <form [formGroup]="form" novalidate>
                                        <div class="col-12 mb-4">
                                            <div class="form-floating">
                                                <select class="form-select" formControlName="filterField"
                                                    aria-label="Floating label select example">
                                                    <option value="email">{{"general.email" | translate}}</option>
                                                    <option value="name">{{"general.name" | translate}}</option>
                                                </select>
                                                <label for="floatingSelect">{{"general.filterBy" | translate}}:</label>
                                            </div>
                                        </div>
                                        <div class="col-12 mb-4">
                                            <div class="form-floating mb-3">
                                                <input type="text" class="form-control" formControlName="value"
                                                    placeholder="Ingresar valor a buscar">
                                                <label for="floatingInput">{{"general.enterValueToSearch" | translate}}</label>
                                            </div>
                                        </div>
                                    </form>

                                </div>
                            </div>
                        </div>

                        <div class="col-12 mt-3 mb-4">
                            <div class="fs-4 text-rslt fw-bold mb-2">
                                {{'general.results' | translate | titlecase}}
                            </div>
                            <div class="seprd2"></div>
                        </div>

                        <div class="row">
                            
                            <div class="col-12 col-lg-6">
                                <div class="text-rslt fw-bold">
                                    <div class="fs-5">{{"general.capacity" | translate}} {{capacity}}</div>
                                </div>
                                <ng-container *ngIf="(results$ | async) as data; else renderLoader"
                                    [ngTemplateOutlet]="renderCheckResponse"
                                    [ngTemplateOutletContext]="{list: data}"></ng-container>
                                   
                                
                            </div>
                            <div class="col-12 col-lg-6">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">{{'name'|translate}}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of userList; let i = index">
                                            <th scope="row">{{i+1}}</th>
                                            <td *ngIf="item | objUser |async as user">
                                                {{user.name | titlecase}} - {{user.email | hiddenEmail}}
                                            </td>
                                            <td>
                                                <button (click)="removeUser(i)" type="button"
                                                    class="btn btn-two p-2">
                                                    <i class="bi bi-trash"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div class="d-flex justify-content-end">
                                    <button (click)="saveUser()" type="button" class="btn btn-one">
                                        {{'save' | translate | titlecase}}
                                    </button>
                                </div>
                            </div>


                        </div>



                    </div>
                </div>
            </div>

            <div
                class="modal-footer d-flex justify-content-center align-items-center justify-content-lg-around flex-wrap">
                <button class="btn btn-three btn-medium-c text-uppercase" type="button" (click)="closeModal()">
                    <i class="bi bi-x-circle"></i>
                    &nbsp;
                    {{ "general.cancel" | translate }}
                </button>
            </div>
        </div>
    </div>
</div>


<ng-template #renderAcademyCard let-item="item">
    <div class="card card-box p-3" (click)="onSelectItem(item)">
        <div class="row g-2 d-flex align-items-center">

            <div class="col-12 px-3">
                <h5 class="card-title fw-bolder text-rslt">
                    <i class="bi bi-person-fill"></i>
                    &nbsp;
                    {{item?.name_institution | uppercase}}
                </h5>
            </div>

            <div class="seprd2"></div>

            <div class="col-12 px-3">
                <p class="card-text m-0">
                    {{'direction' | translate | titlecase}} :
                    <small class="text-muted">
                        {{item.direction}}
                    </small>
                </p>

                <p class="card-text m-0">
                    {{"general.email" | translate}}:
                    <small class="text-muted">
                        {{item.email_institution | hiddenEmail}}
                    </small>
                </p>

                <p class="card-text m-0">
                    {{"general.description" | translate}}:
                    <small class="text-muted">
                        {{item.description}}
                    </small>
                </p>

                <p class="card-text m-0">
                    {{"general.phoneNumber" | translate}}:
                    <small class="text-muted">
                        +{{item.phone_prefijo_institution.phonecode}} {{item.phone_contact}}
                    </small>
                </p>

                <p class="card-text m-0">
                    institutionId:
                    <small class="text-muted">
                        {{item._id}}
                    </small>
                </p>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #renderAmbassadorCard let-item="item">
    <div class="card card-box p-3" (click)="onSelectItem(item)">
        <div class="row g-2 d-flex align-items-center">

            <div class="col-12 px-3">
                <h5 class="card-title fw-bolder text-rslt">
                    <i class="bi bi-person-fill"></i>
                    &nbsp;
                    {{item?.name | uppercase}}
                </h5>
            </div>

            <div class="seprd2"></div>

            <div class="col-12 px-3">
                <p class="card-text m-0">
                    {{"general.email"}}:
                    <small class="text-muted">
                        {{item.email | hiddenEmail}}
                    </small>
                </p>

                <p class="card-text m-0">
                    identification:
                    <small class="text-muted">
                        {{item.identification}}
                    </small>
                </p>
                <p class="card-text m-0">
                    phoneNumber:
                    <small class="text-muted">
                        +{{item.prefijo}} {{item.phone}}
                    </small>
                </p>
                <p class="card-text m-0">
                    uid:
                    <small class="text-muted">
                        {{item.uid}}
                    </small>
                </p>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #renderCardList let-list="list">
    <div class="col-12 mb-4" *ngFor="let item of list">
        <ng-container [ngSwitch]="ownerType">
            <ng-container *ngSwitchCase="'academy'" [ngTemplateOutlet]="renderAcademyCard"
                [ngTemplateOutletContext]="{item: item}"></ng-container>

            <ng-container *ngSwitchCase="'ambassador'" [ngTemplateOutlet]="renderAmbassadorCard"
                [ngTemplateOutletContext]="{item: item}"></ng-container>
        </ng-container>
    </div>
</ng-template>

<ng-template #renderCheckResponse let-list="list">
    <ng-container *ngIf="(list.length > 0); else renderNoRecords" [ngTemplateOutlet]="renderCardList"
        [ngTemplateOutletContext]="{list: list}"></ng-container>
</ng-template>

<ng-template #renderNoRecords>
    <div class="col-12 mb-4">
        <div class="card-box card-box-b3 p-3 mb-3">
            <h5 class="text-center">
               {{"noRecordsFound" | translate}}
            </h5>
        </div>
    </div>
</ng-template>

<ng-template #renderLoader>
    <div class="col-12 mb-4">
        <div class="card-box card-box-b3 p-3 mb-3">
            <div class="text-center">
                <div class="spinner-border" role="status">
                    <span class="visually-hidden">{{"general.loading" | translate}}...</span>
                </div>
            </div>
        </div>
    </div>
</ng-template>