<div class="modal fade" id="modalCuotasPaymentDetails" data-bs-backdrop="static" tabindex="-1"
    aria-labelledby="modalCuotasPaymentDetailsLabel" aria-hidden="true">
    <div class="modal-dialog modal-fullscreen">
        <div class="modal-content">

            <div class="modal-body">
                <div class="container">
                    <div class="row g-3">
                        <div class="col-12">
                            <div class="logo mb-3">
                                <img src="assets/img/cilik/logo.png" alt="">
                            </div>
                            <h5 class="text-center fw-bolder mb-4">
                                {{ "installmentsTerms.title" | translate }}
                            </h5>
                            <p>
                                <strong> 
                                    {{ "general.termsAndConditions" | translate }}: 
                                </strong>
                            </p>
                            <ul>
                                <li *ngFor="let item of [1,1,1,1,1,1]; index as idx">
                                    {{ ("installmentsTerms.terms" | translate)[idx] }}
                                </li>
                            </ul>
                        </div>

                    </div>
                </div>
            </div>
            <div class="modal-footer justify-content-center">
                <button type="button" class="btn btn-three" (click)="closeModal(false)">
                    <i class="bi bi-chevron-left"></i>
                    {{ "general.back" | translate | uppercase}}                    
                </button>
                <button type="button" class="btn btn-one" (click)="closeModal(true)">
                    {{ "general.accept" | translate | uppercase}}
                </button>
            </div>
        </div>
    </div>
</div>