import { Component, Input, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-purchase-full-details',
  templateUrl: './purchase-full-details.component.html',
  styleUrls: ['./purchase-full-details.component.css']
})
export class PurchaseFullDetailsComponent {


  @Input() cart: any;
  globalTotal: any;
  valueTotal: any;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.cart) {
      this.globalTotal = this.cart.totalResumen;
      // console.log('globalTotal', this.globalTotal.paidForPayment);
      // console.log('this.cart', this.cart);
      this.valueTotal = this.globalTotal?.globalTotalToPay - this.globalTotal?.paidForPayment;


    }
  }

}
