<div class="row">

    <ng-container *ngIf="(purchases$ | async) as list; else renderLoaderList" [ngTemplateOutlet]="renderCheckList"
        [ngTemplateOutletContext]="{list: list}"></ng-container>

</div>

<ng-template #renderOrderList let-list="list">
    <div class="col-12" *ngFor="let item of list">
        <app-purchase-list-item-card [item]="item"
            (onItemDetails)="onItemDetails($event)"></app-purchase-list-item-card>
    </div>
</ng-template>

<ng-template #renderCheckList let-list="list">
    <ng-container *ngIf="list.length > 0; else renderNoRecords" [ngTemplateOutlet]="renderOrderList"
        [ngTemplateOutletContext]="{list: list}"></ng-container>
</ng-template>

<ng-template #renderNoRecords>
    <div class="col-12">
        <div class="card-box card-box-b3 p-3 mb-3">
            <h5 class="text-center">
                <i class="bi bi-exclamation-circle"></i>
                &nbsp;
                {{ "general.noRecordsFound"| translate }}
            </h5>
        </div>
    </div>
</ng-template>

<ng-template #renderLoaderList>
    <div class="col-12" *ngFor="let item of [1,1,1,1]">
        <ng-container [ngTemplateOutlet]="renderLoaderListItem"></ng-container>
    </div>
</ng-template>

<ng-template #renderLoaderListItem>
    <div class="card card-box p-3 mb-3">
        <div class="row g-2 d-flex align-items-center">
            <div class="col-12 px-3">
                <h5 class="card-title fw-bolder text-rslt placeholder-glow">
                    <span class="placeholder col-12"></span>
                </h5>
            </div>

            <div class="seprd2"></div>

            <div class="col-12 px-3">
                <div class="row mb-1">
                    <div class="col-12 col-md-6">
                        <p class="card-text mb-0 placeholder-glow">
                            <span class="placeholder col-12"></span>
                        </p>
                    </div>
                    <div class="col-12 col-md-6">
                        <p class="card-text mb-0 placeholder-glow">
                            <span class="placeholder col-12"></span>
                        </p>
                    </div>
                </div>

                <div class="d-flex flex-row align-content-center justify-content-between">
                    <div class="d-flex justify-content-center align-items-center">
                        <p class="card-text mb-0 placeholder-glow">
                            <span class="placeholder col-12" style="height: 36px; width: 12rem;"></span>
                        </p>
                    </div>
                    <div>
                        <button type="button" class="btn-icon btn-icon-two" disabled>
                            <div class="spinner-border spinner-border-sm" role="status">
                                <span class="visually-hidden">{{ "general.loading"| translate }}...</span>
                            </div>
                        </button>
                    </div>
                </div>

            </div>
        </div>
    </div>
</ng-template>