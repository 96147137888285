<div class="card card-box p-3 mb-3">
    <div class="row g-2 d-flex align-items-center">
        <div class="col-12 px-3">
            <div class="d-flex justify-content-between">
                <!-- <span class="text-rslt fw-bold fs-5">
                    <i class="bi bi-ticket-perforated"></i>
                    &nbsp;
                    {{item?.title | translate}}
                </span>

                <span class="d-none d-md-block">
                    <i class="bi bi-person-fill"></i>
                    {{item?.capacity}}
                </span> -->

                <span class="text-rslt fw-bold fs-6">
                    <i class="bi bi-person-fill"></i>
                    {{item?.capacity}}
                    |
                    {{item?.title | translate}}
                </span>

                <div class="c-icon d-flex align-items-center">
                    <img src="assets/img/icon-ticket-gray.png" alt="">
                </div>
            </div>
        </div>

        <div class="seprd2"></div>

        <div class="col-12 px-3">

            <p class="card-text mb-0" *ngIf="item?.include">
                {{"general.include" | translate}}:
                <small class="text-muted">
                    {{item.include | translate}}
                </small>
            </p>
            <p class="card-text mb-0" *ngIf="item?.notInclude">
                {{"general.notInclude" | translate}}:
                <small class="text-muted">
                    {{item.notInclude | translate}}
                </small>
            </p>

            <p class="card-text mb-0" *ngIf="item">
                Fechas:
                <span class="badge bg-danger" [ngClass]="(!isLast) ? 'me-1': ''" *ngFor="let item of item.dates; last as isLast">
                    {{item.date}}
                </span>
            </p>

            <div class="d-flex justify-content-between">
                <div class="d-flex justify-content-center align-items-md-center">
                    <span class="fw-bold fs-4">
                        {{"general.total" | translate}}: USD {{totales | number: '1.0-0'}}
                    </span>
                </div>
            </div>

        </div>
    </div>
</div>
