<div class="fs-2 fw-bold text-center mb-3">
    {{'general.purchasingSummary' | translate}}
</div>

<div class="col-12 mb-4">
    <nav>
        <div class="nav nav-tabs" id="nav-tab" role="tablist">
            <button class="nav-link active" id="nav-pendings-tab" data-bs-toggle="tab" data-bs-target="#nav-pendings"
                type="button" role="tab" aria-controls="nav-pendings" aria-selected="true">
                {{ 'general.pending' | translate | titlecase }}
            </button>
            <button class="nav-link" id="nav-completed-tab" data-bs-toggle="tab" data-bs-target="#nav-completed"
                type="button" role="tab" aria-controls="nav-completed" aria-selected="false">
                {{ 'general.completed' | translate | titlecase }}
            </button>
            <button class="nav-link" id="nav-rejected-tab" data-bs-toggle="tab" data-bs-target="#nav-rejected"
                type="button" role="tab" aria-controls="nav-rejected" aria-selected="false">
                {{ 'general.rejected' | translate | titlecase }}
            </button>
        </div>
    </nav>
</div>

<div class="col-12">
    <div class="tab-content" id="nav-tabContent">
        <div class="tab-pane fade show active" id="nav-pendings" role="tabpanel" aria-labelledby="nav-pendings-tab">
            <ng-container *ngIf="(purchasesListP$ | async) as purchasesListP; else loading">
                <ng-container *ngIf="purchasesListP.length > 0; else noPurchases">
                    <div class="col-12 mb-4" *ngFor="let item of purchasesListP">
                        <app-my-purchases-list-item [order]="item" (onShowDetails)="onShowDetails($event)"></app-my-purchases-list-item>
                    </div>
                </ng-container>
            </ng-container>
        </div>
        <div class="tab-pane fade" id="nav-completed" role="tabpanel" aria-labelledby="nav-completed-tab">
            <ng-container *ngIf="(purchasesListC$ | async) as purchasesListC; else loading">
                <ng-container *ngIf="purchasesListC.length > 0; else noPurchases">
                    <div class="col-12 mb-4" *ngFor="let item of purchasesListC">
                        <app-my-purchases-list-item [order]="item" (onShowDetails)="onShowDetails($event)"></app-my-purchases-list-item>
                    </div>
                </ng-container>
            </ng-container>
        </div>
        <div class="tab-pane fade" id="nav-rejected" role="tabpanel" aria-labelledby="nav-rejected-tab">
            <ng-container *ngIf="(purchasesListR$ | async) as purchasesListR; else loading">
                <ng-container *ngIf="purchasesListR.length > 0; else noPurchases">
                    <div class="col-12 mb-4" *ngFor="let item of purchasesListR">
                        <app-my-purchases-list-item [order]="item" (onShowDetails)="onShowDetails($event)"></app-my-purchases-list-item>
                    </div>
                </ng-container>
            </ng-container>
        </div>
    </div>
</div>



<app-purchase-summary-modal-details *ngIf="show"></app-purchase-summary-modal-details>

<ng-template #noPurchases>
    <div class="col-12 mb-4">
        <div class="card card-box p-3 mb-3">
            <div class="row g-2 d-flex align-items-center">
                <div class="col-12 px-3">
                    <p class="m-0 text-center">
                       {{'general.noPurchases' | translate}} 
                    </p>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #loading>
    <div class="col-12 mb-4" *ngFor="let item of [1,1,1]">
        <div class="card card-box p-3 mb-3">
            <div class="row g-2 d-flex align-items-center">
                <div class="col-12 px-3 d-flex justify-content-between placeholder-glow">
                    <span class="placeholder bg-danger col-5" style="height: 36px;"></span>
                    <span class="placeholder col-5" style="height: 36px;"></span>
                </div>
                <div class="seprd2"></div>
                <div class="col-12 px-3">
                    <p class="m-0 placeholder-glow">
                        <span class="placeholder col-6"></span>
                    </p>
                    <div class="d-flex justify-content-end">
                        <a href="#" tabindex="-1" class="btn btn-one disabled placeholder col-6"></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
