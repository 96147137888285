<div class="card card-box p-3 mb-3">
    <div class="row g-2 d-flex align-items-center">
        <div class="col-12 px-3">
            <div class="d-flex justify-content-between">
                <span class="text-rslt fw-bold fs-5">
                    {{ "general.categories" | translate }}
                </span>
            </div>
        </div>
        <div class="seprd2"></div>
        <div class="col-12 px-3">

            <p class="border-bottom fw-bold m-0">
                {{ "general.additionalCategories" | translate }}:
            </p>
            <div class="seprd m-0 mb-2"></div>
            <p 
                class="m-0 fw-bold" 
                [ngClass]="{'border-bottom': !isLast}" 
                *ngFor="let item of additionalCategoryPasses; last as isLast"
            >

                <ng-container *ngIf="item.type !== 'group'; else showGroupList">
                    {{ "general.amountOf" | translate }} {{'general.'+item.type | translate | titlecase}}: {{getTotalAdditional(item)}}
                </ng-container>

                <ng-template #showGroupList>

                    <!-- {{ "general.amountOf" | translate }} {{item.type | translate | titlecase}}: {{getTotalAdditional(item)}}
                    <br> -->

                    <ng-container *ngFor="let row of item.data; index as rowIdx; last as isLastRow">
                        <strong>{{'general.group' | translate | titlecase}} #{{rowIdx + 1}}</strong><br>
                        - {{'general.numberOfMembers' | translate}}: {{row.quantity}}

                        <br *ngIf="!isLastRow">
                    </ng-container>

                </ng-template>

            </p>

            <p class="m-0 mt-4">
                {{ "general.valueWithoutDiscount" | translate }}: 
                <span class="text-decoration-line-through">
                    USD {{additionalCategoryPassesAmountFullPrice | mask:'separator.0':','}}
                </span>
            </p>
            <p class="m-0">
                {{ "general.discount" | translate }}: USD {{discount | mask:'separator.0':','}}
            </p>
            <div class="d-flex justify-content-between">
                <span class="fs-4 fw-bolder">
                    {{'general.total' | translate | uppercase}}: USD {{subTotal | mask:'separator.0':','}}
                </span>
                <div>
                    <button type="button" 
                        class="btn-icon btn-icon-one"
                        (click)="onUpdate.next(true)">
                        <i class="bi bi-pencil"></i>
                    </button>
                    &nbsp;
                    <button type="button" 
                        class="btn-icon btn-icon-two"
                        (click)="onRemove.next(true)">
                        <i class="bi bi-trash3"></i>
                    </button>
                </div>
            </div>


        </div>
    </div>
</div>