<div class="container-fluid">

    <ng-container *ngIf="(profile$ | async) as profile; then renderFullHeader; else renderOnlyLanguage">

    </ng-container>

    <!-- <div class="row">
        <div class="col pb-5">
            <div class="logo d-flex justify-content-center">
                <img src="assets/img/cilik/logo.png" alt="">
            </div>
        </div>
    </div> -->
</div>

<ng-template #renderFullHeader>
    <div class="row align-items-center">
        <div class="col">
            <div class="logo" routerLink="/dashboard">
                <img src="assets/img/cilik/logo.png" alt="">
            </div>

        </div>
        <div class="col d-flex align-items-center justify-content-between justify-content-md-end header-cilik">

            <app-language-bar></app-language-bar>

            <div class="d-flex align-items-center">

                <ng-container *ngIf="(cart$ | async) as cart">
                    <div class="btn-navbar-c d-flex justify-content-center align-items-center ms-3"
                        routerLink="/pages/cart">
                        <span class="icon-header active-cart  text-whi fs-4">
                            <i class="bi bi-cart3"></i>
                            <div class="ntf-cart" *ngIf="cart.product.length > 0">
                                {{cart.product.length}}
                            </div>
                        </span>
                    </div>
                </ng-container>

                <div class="seprd-h"></div>
                <!-- 
                <ng-container *ngIf="isAnonymous$ | async as isAnonymous">
                    <div *ngIf="!isAnonymous" class="btn-logout d-flex align-items-center" (click)="logout()">

                        <div class="icon-header">
                            <span class="text-ps fw-bold">
                                {{ "general.logOut" | translate }}
                            </span>
                            <img src="assets/img/cilik/icon-28.png" alt="">
                        </div>
                    </div>

                </ng-container> -->
                
                <div class="btn-logout d-flex align-items-center" (click)="logout()">
                    <div class="icon-header">
                        <span class="text-ps fw-bold">
                            {{ "general.logOut" | translate }}
                        </span>
                        <i class="bi bi-box-arrow-right"></i>
                    </div>
                </div>


            </div>
        </div>
    </div>
</ng-template>

<ng-template #renderOnlyLanguage>
    <div class="row">
        <div class="col d-flex justify-content-between header-cilik">
            <app-language-bar></app-language-bar>
        </div>
    </div>
</ng-template>