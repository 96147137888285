import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pages-layout',
  templateUrl: './pages-layout.component.html',
  styleUrls: ['./pages-layout.component.css']
})
export class PagesLayoutComponent implements OnInit {

  public ayuda = false;

  constructor() { }

  ngOnInit(): void {
  }

}
