<div class="container-fluid footerbg">
    <footer class="d-flex flex-wrap justify-content-between align-items-center py-3 border-top">
        <div class="col-md-4 d-flex align-items-center">
            <div class="logofoot">
                <img src="assets/images/footer/logo.png" alt="">
            </div>
            <div class="text text-small ms-3">
                {{ 'footer.label' | translate }}
            </div>
        </div>

        <ul class="nav col-md-4 justify-content-end list-unstyled d-flex">
            <li class="ms-3">
                <a class="text-muted" href="javascript:void(0)">
                    <i class="bi bi-youtube"></i>
                </a>
            </li>
            <li class="ms-3">
                <a class="text-muted" href="javascript:void(0)">
                    <i class="bi bi-instagram"></i>
                </a>
            </li>
            <li class="ms-3">
                <a class="text-muted" href="javascript:void(0)">
                    <i class="bi bi-twitter"></i>
                </a>
            </li>
        </ul>
    </footer>
</div>