import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-registro-exitoso',
  templateUrl: './registro-exitoso.component.html',
  styleUrls: ['./registro-exitoso.component.css']
})
export class RegistroExitosoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void { }
    
}
