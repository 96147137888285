<div class="modal fade modalTCcategoriasAdd" id="purchaseInstallmentsModal" data-bs-backdrop="static"
    data-bs-keyboard="false" tabindex="-1" aria-labelledby="modalTCcategoriasALabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                {{'metodos de pago' | translate | titlecase}}
                <button type="button" class="btn-close" aria-label="Close" (click)="closeModal()"></button>
            </div>

            <div class="modal-body">
                <div class="accordion" id="accordionExample">
                    <div class="accordion-item">
                        <h2 class="accordion-header">
                            <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                Tarjeta de credito o debito
                            </button>
                        </h2>
                        <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                <app-tucompra-form [amount]="amount" [type]="'presale-cuota'"
                                    (onSendForm)="onTuCompraCallback($event)"></app-tucompra-form>

                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                Transferencia bancaria
                            </button>
                        </h2>
                        <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                            <div class="accordion-body">

                                <app-purchase-installments-voucher [amount]="amount" [orderDoc]="orderDoc" [item]="item"
                                    (onSendForm)="onVoucherCallback($event)"></app-purchase-installments-voucher>

                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                Paypal
                            </button>
                        </h2>
                        <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                <app-paypal-button [amount]="amount"
                                    (onCallback)="onPaypalCallback($event)"></app-paypal-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>