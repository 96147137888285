<div class="card card-box p-3 mb-3">
    <div class="row g-2 d-flex align-items-center">
        <div class="col-12 px-3">
            <div class="d-flex justify-content-between align-items-center">
                <span class="text-rslt fw-bold fs-5">
                    {{ "general.Official-WLDC-Plan" | translate }}
                </span>

                <span class="text-end">
                    {{ "general.purchase-order" | translate }}
                    <br>
                    {{order?.id || '084H36BT'}}
                </span>
            </div>
        </div>
        <div class="seprd2"></div>
        <div class="col-12 px-3">
            <p class="m-0">
                {{ "general.see-detail-of-the-purchase" | translate }}
            </p>
            <div class="d-flex justify-content-end">
                <button class="btn btn-one" type="button">
                    {{ "general.add-room" | translate }}
                </button>
            </div>
        </div>
    </div>
</div>