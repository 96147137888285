<div class="modal fade modalTCcategoriasAdd" id="modalStoreOnlyCategories" data-bs-backdrop="static"
    data-bs-keyboard="false" tabindex="-1" aria-labelledby="modalTCcategoriasALabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">

            <div class="modal-header">
                <button type="button" class="btn-close" aria-label="Close" (click)="closeModal()"></button>
            </div>

            <div class="modal-body">
                <div class="container">
                    <div class="row g-3">
                        <div class="col-12">

                            <ng-container [ngSwitch]="step">
                                <ng-container *ngSwitchCase="'category'" [ngTemplateOutlet]="renderCategories"></ng-container>

                                <ng-container *ngSwitchCase="'quantity'"
                                    [ngTemplateOutlet]="renderForm"
                                    [ngTemplateOutletContext]="{field: f['categoryTypes'].value}"
                                ></ng-container>
                            </ng-container>

                        </div>
                    </div>
                </div>
            </div>

            <div class="modal-footer d-flex justify-content-center align-items-center justify-content-lg-around flex-wrap">
                <button type="button" class="btn btn-one text-uppercase" *ngIf="item && step == 'quantity'" (click)="onSubmit()">
                   {{ "general.add" | translate }} 
                   <!-- Añadir -->
                    &nbsp;
                    <i class="bi bi-cart-plus"></i>
                </button>
                <button class="btn btn-three btn-medium-c text-uppercase" type="button" (click)="closeModal()">
                    <i class="bi bi-x-circle"></i>
                    &nbsp;
                    {{ "general.cancel" | translate }}
                </button>
            </div>
        </div>
    </div>
</div>

<ng-template #renderForm let-field="field">
    <app-input-number-form
        *ngIf="item"
        [label]="description"
        placeholder="0"
        [quantity]="0"
        [min]="0"
        [max]="99"
        (onUpdateQuantity)="onInputValueChange($event)"
    ></app-input-number-form>

    <p class="text-center mb-0 mt-4"> 
        <span class="fw-bold fs-4">{{'general.value' | translate}}: USD {{price}}</span>
    </p>

    <p class="text-center"> 
        <span class="fw-bold fs-4">{{'general.total' | translate}}: USD {{totales}}</span>
    </p>

    <p class="text-center">
        <ng-container *ngIf="submitted" [ngTemplateOutlet]="renderFormVM" [ngTemplateOutletContext]="{field: 'quantity'}"></ng-container>
    </p>
</ng-template>

<ng-template #renderCategories>
    <div class="d-flex justify-content-center align-items-center justify-content-lg-around flex-wrap gap-2">
        <button type="button" class="btn btn-one text-uppercase" *ngFor="let item of categoryTypes" (click)="onSelectCategoryType(item)">
            {{item.label | translate | uppercase}}
        </button>
    </div>
</ng-template>

<ng-template #renderFormVM let-field="field">
    <ng-container *ngFor="let item of vm[field]">
        <span class="badge bg-danger" *ngIf="f[field].hasError(item.type)">
            {{ item.message | translate }}
        </span>
    </ng-container>
</ng-template>