<div class="row mb-4">
    <div class="col-12 mb-4">
        <p>
            {{'Entendemos la importancia de la planificación financiera. Por eso, ofrecemos la opción de pagar en cuotas
            fijas, permitiéndote distribuir el costo de tu compra con un monto exacto a pagar mes a mes, sin sorpresas.
            Disfruta de una tasa fija y un plazo definido desde el inicio.'
            | translate }}
        </p>

        <p>
            {{'Tus cuotas quedarán de la siguiente manera'| translate }}:
        </p>

    </div>

    <div class="list-group">
        <ng-container *ngFor="let item of installments; let i=index">
            <a class="list-group-item list-group-item-action active" [ngClass]="{'active': i == 0}" aria-current="true">
                <div class="d-flex w-100 justify-content-between">
                    <h5 class="mb-1">{{"payedAt" | translate | titlecase}}: {{item.date }}</h5>
                    <small>
                        {{'Pendiente'| translate }}: {{item.quota}}
                    </small>
                </div>
                <p class="mb-1">
                    {{'Monto'| translate }}: {{item.amount | currency:'USD':'symbol':'1.2-2'}}
                </p>
            </a>
        </ng-container>
    </div>

    <div class="col-12 mb-4 pt-3">
        <p>
            {{'Es importante resaltar que el pago de la primera cuota debe realizarse dentro de las 48 horas siguientes
            a tu compra. De no hacerlo, lamentablemente tu orden quedará cancelada.'| translate }}
        </p>

        <p>
            {{'Sigue las instrucciones para completar tu transacción. Si tienes dudas o inquietudes sobre el proceso de
            pago en cuotas, estamos aquí para ayudarte.'| translate }}:

        </p>

        <p>
            <strong>
                {{'Aplican términos y condiciones.'| translate }}
            </strong>
        </p>
    </div>
    <div class="col-12">
        <button type="button" class="btn btn-primary" (click)="confirmOption()">
            <i class="bi bi-check2-square"></i>
            &nbsp;
            {{"general.confirm" | translate | titlecase}}
        </button>
    </div>

</div>