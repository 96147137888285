<div class="modal fade" [id]="_id" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-fullscreen">
        <div class="modal-content">



            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row g-3">
                        <div class="col-12">

                            <div class="card card-box">
                                <div class="card-body">

                                    <h5 class="card-title fw-bolder text-rslt">
                                        {{"hotelAndEvent.completeTheFieldsToSearchForRooms" | translate}}
                                    </h5>

                                    <div class="seprd2"></div>

                                    <label class="form-label fw-bolder mt-2">
                                        {{"hotelAndEvent.chooseTheDatesOfYourStay" | translate}}
                                    </label>

                                    <app-input-range-calendar *ngIf="item" [autoUpdateInput]="false"
                                        [minDate]="item?.minDate" [maxDate]="item?.maxDate"
                                        [startDate]="item?.startDate" [endDate]="item?.endDate"
                                        [cancelLabel]="'general.cancel' | translate"
                                        [applyLabel]="'general.apply' | translate" class="selectCalendar"
                                        cancelButtonClasses="btn btn-three" applyButtonClasses="btn btn-one"
                                        (onUpdateDates)="onInputDatesChange($event)"></app-input-range-calendar>

                                    <p class="card-text text-center">
                                        <ng-container *ngIf="submitted" [ngTemplateOutlet]="renderFormVM"
                                            [ngTemplateOutletContext]="{field: 'dates'}"></ng-container>
                                    </p>

                                    <div class="d-flex align-items-center">
                                        <label class="form-label fw-bolder mt-2">
                                            {{"hotelAndEvent.indicatesTheNumberOfPeopleInTheRoom" | translate}}
                                        </label>

                                        <app-input-group-number-form #inputNumber *ngIf="item" [max]="3"
                                            (onUpdateQuantity)="onInputNumberChange($event)"></app-input-group-number-form>
                                    </div>



                                    <div
                                        class=" d-flex justify-content-center align-items-center justify-content-lg-around flex-wrap mt-3">
                                        <button class="btn btn-one btn-medium text-uppercase" type="button"
                                            (click)="searchRooms()">
                                            <i class="bi bi-binoculars"></i>
                                            &nbsp;
                                            {{"general.search" | translate | uppercase}}
                                        </button>
                                    </div>

                                    <p class="card-text text-center">
                                        <ng-container *ngIf="submitted" [ngTemplateOutlet]="renderFormVM"
                                            [ngTemplateOutletContext]="{field: 'capacity'}"></ng-container>
                                    </p>

                                </div>
                            </div>
                        </div>

                        <div class="col-12 text-start mt-4">
                            <div class="fs-4 fw-bold text-rslt">
                                {{"general.availableRooms" | translate}}
                            </div>
                            <div class="seprd2"></div>
                        </div>

                        <ng-container
                            *ngIf="(roomList.length > 0); then renderRoomsList; else renderNoRooms"></ng-container>

                    </div>
                </div>
            </div>

            <div
                class="modal-footer d-flex justify-content-center align-items-center justify-content-lg-around flex-wrap">
                <button class="btn btn-three btn-medium text-uppercase" type="button" (click)="closeModal()">
                    <i class="bi bi-x-circle"></i>
                    &nbsp;
                    {{"general.cancel" | translate}}
                </button>
                <!-- <button type="button" class="btn btn-one text-uppercase" *ngIf="item" (click)="onSubmit()">
                    Añadir
                    &nbsp;
                    <i class="bi bi-cart-plus"></i>
                </button> -->
            </div>
        </div>
    </div>
</div>

<ng-template #renderRoomsList>
    <div class="col-12" *ngFor="let item of roomList; first as isFirst">
        <app-hotel-and-event-room-list-item-card [item]="item" [recomendation]="isFirst"
            (onSelectRoom)="onSelectRoom($event)"></app-hotel-and-event-room-list-item-card>
    </div>
</ng-template>

<ng-template #renderNoRooms>
    <div class="col-12">
        <div class="card card-box">
            <div class="card-body">
                <h5 class="text-center">
                    {{"hotelAndEvent.availableRoomsWillBeShownHere" | translate}}
                </h5>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #renderFormVM let-field="field">
    <ng-container *ngFor="let item of vm[field]">
        <span class="badge bg-danger" *ngIf="f[field].hasError(item.type)">
            {{ item.message | translate }}
        </span>
    </ng-container>
</ng-template>