<div class="container-lg">
    <div class="row my-lg-5 my-3 d-flex justify-content-center">
        <div class="col-12 col-lg-10">
            <div class="card p-2 p-lg-5">
                <app-header-cilik></app-header-cilik>

                <app-header-welcome></app-header-welcome>

                <router-outlet></router-outlet>

                <app-footer-cilik></app-footer-cilik>
            </div>
        </div>
    </div>
</div>
