import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { finalize } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UploadFileService {

  constructor(
    private storage: AngularFireStorage,
  ) { }

  async uploadFileDocumentIntoRoute(filePath: string, file: File) {
    return new Promise(async (resolve, reject) => {
      try {

        /** Crear Referencia en el Bucket */
        const fileRef = this.storage.ref(filePath);

        /** Crear tarea para subir archivp */
        const task = this.storage.upload(filePath, file);

        /** 
         * Ejecutar tarea y esperar respuesta 
         * - Retorna la URL del archivo subido
         */
        task
          .snapshotChanges()
          .pipe(
            finalize(() => {
              fileRef.getDownloadURL().subscribe((url) => {
                let uploadProgress = 0;
                resolve(url);
              });
            })
          )
          .subscribe();

      } catch (err) {
        return reject(err);
      }
    });
  }

  async uploadBase64DocumentIntoRoute(filePath: string, base64: string) {
    return new Promise(async (resolve, reject) => {
      try {
        // Convertir Base64 a Blob
        const file = this.base64ToFile(base64, filePath);

        // Crear Referencia en el Bucket
        const fileRef = this.storage.ref(filePath);

        // Crear tarea para subir archivo
        const task = this.storage.upload(filePath, file);

        // Ejecutar tarea y esperar respuesta
        task
          .snapshotChanges()
          .pipe(
            finalize(() => {
              fileRef.getDownloadURL().subscribe((url) => {
                resolve(url);
              });
            })
          )
          .subscribe();

      } catch (err) {
        return reject(err);
      }
    });
  }

  private base64ToFile(base64: string, filePath: string): File {
    const arr: any = base64.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filePath, { type: mime });
  }

  async removeByURL(url: string) {
    await this.storage.storage.refFromURL(url).delete();
    return true;
  }

}
