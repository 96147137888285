<div class="container">
    <div class="row">
        <div class="col-12 col-md-6 d-none d-md-block">
            <div class="imgcoll">
                <img src="/assets/img/collageX.png" alt="">
            </div>
        </div>
        <div class="col-12 col-md-6 mb-3">
            <div class="fs-4 mb-3">
                <a class="text-light" href="javascript:void(0)">FAQ</a>
            </div>
            <div class="fs-4 mb-3">
                <a class="text-light" href="https://xpocrypto.io/#speakers">SPEAKERS</a>
            </div>
            <div class="fs-4 mb-3">
                <a class="text-light" href="javascript:void(0)">OVERVIEW</a>
            </div>
            <div class="fs-4 mb-3">
                <a class="text-light" href="javascript:void(0)">XPO NEWS</a>
            </div>
            <div class="fs-4 mb-3">
                <a class="text-light" href="https://xpocrypto.io/#homesponsor">OUR SPONSORS</a>
            </div>
            <div class="fs-4 mb-3">
                <a class="text-light" href="https://xpocrypto.io/#tituloevento">EVENTS</a>
            </div>
            <div class="fs-6 mb-3 text-light" *ngIf="false">
                Subscribe to our newsletter
            </div>
            <div class="formcct" *ngIf="false">
                <div class="d-flex justify-content-between mb-3">
                    <div class="campo">
                        <input type="text" class="form-control text-center" placeholder="Name" aria-label="Name">
                    </div>
                    <div class="campo">
                        <input type="text" class="form-control text-center" placeholder="Last name" aria-label="Last name">
                    </div>
                </div>
                <div class="campo mb-3">
                    <input type="text" class="form-control text-center" placeholder="Email" aria-label="Email">
                </div>
                <div class="campo mb-3">
                   <button class="btn w-100 text-light">Subscribe</button>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-3 pb-3">
        <div class="col text-light text-center">
            © Copyright 2022 XPO.CRYPTO S.A.S. All Rights Reserved.
        </div>
    </div>
</div>