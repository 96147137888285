<div class="card card-box p-3 mb-3">
    <!-- <div class="pr-3">
        <button type="button" class="btn-close float-end" aria-label="Close"></button>
        &nbsp;
    </div> -->


    <div class="row">
        <div class="col-12 px-3">
            <div class="d-flex justify-content-between flex-wrap">
                <span class="fw-bold fs-5">
                    {{ "general.room" | translate }} #{{index + 1}}
                </span>

                <span>
                    {{ "general.pricePerPerson" | translate }}: USD {{pricePerPeople | mask:'separator.0':','}}
                    &nbsp;
                    <span class="btn-close-card-item"><i class="bi bi-x-lg" (click)="removeItem()"></i></span>
                </span>
            </div>
        </div>
    </div>
    <div class="seprd"></div>
    <div class="row">
        <div class="col-12 mb-2">
            <!-- {{item?.ubicationTypeDescription | uppercase }} {{item?.description | uppercase}} -->
            {{'hotel.ubitacion.'+item.ubicationType | translate | uppercase}} - {{ 'hotel.roomType.'+item.roomCode | translate | uppercase}}
        </div>

        <div class="col-12 col-sm-6 col-md-6">
            <div class="row p-2">
                <div class="col-12">
                    <app-input-number-form-two 
                        [label]="('general.roomCardAddPeopleIntoTheroom' | translate)" 
                        [min]="1" [max]="3"
                        [quantity]="item.capacity"
                        (onUpdateQuantity)="onUpdateQuantity($event)"
                    ></app-input-number-form-two>
                    <span class="">{{ "general.roomCardMaxCapacity" | translate }}</span>
                </div>
            </div>
        </div>
        <div class="col-12 col-sm-6 col-md-6 align-self-center">
            <div class="row p-2">
                <div class="col-12  d-flex justify-content-end">
                    <div class="card-box card-box-wfc p-3">
                        <h4 class="text-end py-2 m-0">
                            {{ "general.amount" | translate | titlecase }} USD {{subTotal | mask:'separator.0':','}}
                        </h4>
                    </div>                   
                </div>
            </div>
        </div>
        

        <!-- TODO: Habilitar cambio de tipo de habitación -->
        <!-- <div class="seprd"></div>
        <div class="col-12">
           <div class="mb-3">Selecciona una de las opciones de cama:</div>
            <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                <label class="form-check-label" for="flexCheckDefault">
                    2 camas semidobles
                </label>
            </div>
            <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                <label class="form-check-label" for="flexCheckDefault">
                    1 cama king
                </label>
            </div>                    
        </div> -->

    </div>
    <div class="seprd"></div>
    <div class="row">
        <div class="col-12 col-md-6">
            <div class="col-12 col-sm-12 col-md-8">
                <button class="btn btn-three mt-2" type="button" (click)="onUpdateRoom.next(index)">
                    {{ "general.changeRoomType" | translate | uppercase }}
                </button>
                <button class="btn btn-three mt-2" type="button" (click)="onUpdateAdditionalDays.next(index)">
                    {{ "general.extraNight" | translate | uppercase }} <ng-container *ngIf="additionalAmount > 0">({{additionalsDays}})</ng-container>
                </button>
            </div>
        </div>
        <!-- <div class="col-12 col-sm-6 col-md-6">
            <div class="row p-2">
                <div class="col-12 text-end fs-5">
                    {{ "general.thisPlanIncludes" | translate }}: 
                    <br>
                    <a data-bs-toggle="modal" href="#modalPlanDetails" role="button" class="fw-bold text-decoration-none text-danger">
                        {{ "general.seeHere" | translate }}
                    </a>
                </div>
            </div>
        </div> -->
       

        <!-- <div class="col-12">
            <div class="card-body">
            <h5 class="card-title">Card title</h5>
            <p class="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
            <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
            </div>
        </div> -->
    </div>
</div>