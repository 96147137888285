<div class="card card-box mt-3 pt-3">
    <div class="card-body">

        <div class="d-flex justify-content-between pb-2">
            <div class="d-flex align-items-center text-rslt fw-bold fs-6">
                <ng-container [ngTemplateOutlet]="renderCapacityIcon"></ng-container>
                | {{item.locationLabel | translate}}
            </div>

            <div class="c-icon d-flex align-items-center">
                <img src="assets/img/icon-bed-gray.png" alt="">
                <img src="assets/img/icon-ticket-gray.png" alt="">
            </div>
        </div>

        <div class="seprd2"></div>

        <div class="card-plan mb-1">
            <div class="recomendation" *ngIf="recomendation">
                {{"general.mostEconomicalOption" | translate | uppercase}}
            </div>
            <div class="row align-items-center">
                <div class="col-12 col-md-6 mt-3">
                    <div class="fs-3 mb-3 fw-bold lh-1">
                        {{item.title | translate}}
                    </div>
                    <div class="fw-bold">
                        <span class="fw-bold">
                            {{"general.checkInDate" | translate}}:
                        </span>
                        <span class="text-rslt">
                            <ng-container *ngIf="checkIn" [ngTemplateOutlet]="renderDateParsed"
                                [ngTemplateOutletContext]="{date: checkIn}"></ng-container>
                        </span>
                    </div>
                    <div class="fw-bold">
                        <span class="fw-bold">
                            {{"general.checkOutDate" | translate}}:
                        </span>
                        <span class="text-rslt">
                            <ng-container *ngIf="checkOut" [ngTemplateOutlet]="renderDateParsed"
                                [ngTemplateOutletContext]="{date: checkOut}"></ng-container>
                        </span>
                    </div>
                    <div>
                        {{"general.totalNights" | translate}}: {{nroNights}}
                    </div>
                    <!-- <div>Cantidad de habitaciones: 1</div> -->
                    <div>
                        {{"general.amountOfPeople" | translate}}:
                        {{item.capacity}} {{"general." + ((item.capacity == 1) ? 'adult' : 'adults') | translate}}
                    </div>

                    <div class="c-icon my-2">
                        {{item.nroBeds}}

                        <!-- camas dobles  -->
                        <ng-container *ngFor="let item of item.bedsLabel; last as isLast">
                            {{item | translate}}
                            <ng-container *ngIf="(!isLast)">
                                {{'general.or' | translate}}&nbsp;
                            </ng-container>
                        </ng-container>

                        <img src="assets/img/icon-bed-gray.png" alt="">
                    </div>

                </div>
                <div class="col-12 col-md-6 d-flex justify-content-center  justify-content-md-end">
                    <div class="img-card">
                        <!-- <img src="assets/img/hotel.jpg" alt=""> -->
                        <img src="assets/images/rooms/{{item.subcode}}.png" alt="">
                    </div>
                </div>
                <div class="col-12">
                    <p class="card-text mb-0">
                        <!-- Incluye: -->
                        {{ "general.include" | translate}}:
                        <small class="text-muted">
                            {{item.include | translate}}
                        </small>
                    </p>
                    <p class="card-text">
                        <!-- No Incluye: -->
                        {{ "general.notInclude" | translate}}:
                        <small class="text-muted">
                            {{item.notInclude | translate}}
                        </small>
                    </p>
                </div>
            </div>
        </div>





        <!-- <p class="card-text"> -->
        <!-- {{ 'hotel.roomType.'+row.roomCode | translate | uppercase}} -->
        <!-- {{row.description}} -->
        <!-- {{item.title}}
        </p> -->
        <!-- <p class="card-text">
            {{"plansCard.include" | translate | titlecase}}:
            <small class="text-muted">
                {{item.include}}
            </small>
        </p>
        <p class="card-text">
            {{"plansCard.notInclude" | translate | titlecase}}:
            <span class="text-muted">
                {{item.noInclude}}
            </span>
        </p> -->

        <div class="d-flex flex-column flex-md-row align-content-md-center justify-content-md-between">
            <div class="d-flex justify-content-center align-items-md-center">
                <span class="fw-bold fs-4">
                    {{"general.total" | translate }}: USD {{totales | number: '1.2-2'}} 
                </span>
            </div>

            <div class="d-flex justify-content-center">
                <ng-container *ngIf="(!loading); then renderCartBtn; else renderLoaderBtn"></ng-container>
            </div>
        </div>

    </div>
</div>


<ng-template #renderDateParsed let-date="date">
    {{date | date: 'd MMM yyyy'}}
</ng-template>

<ng-template #renderCartBtn>
    <a href="javascript:void(0)" class="btn btn-one btn-small" [ngClass]="{'disabled': item.dates.length == 0}"
        (click)="selectRoom()">
        {{"general.add" | translate }}
        &nbsp;
        <i class="bi bi-cart-plus"></i>
    </a>
</ng-template>

<ng-template #renderLoaderBtn>
    <a href="javascript:void(0)" class="btn btn-one btn-small disabled">
        <div class="spinner-border spinner-border-sm" role="status">
            <span class="visually-hidden">{{"general.loading" | translate }}...</span>
        </div>
    </a>
</ng-template>

<ng-template #renderCapacityIcon>
    <i class="bi" [ngClass]="{'bi-person-fill': item.capacity == 1, 'bi-people-fill': item.capacity > 1}"></i>
    {{item.capacity}}
</ng-template>