<!-- Modal -->
<div class="modal fade" id="modalPermissionRoleAdd" tabindex="-1" aria-labelledby="modalPermissionRoleAddLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="modalPermissionRoleAddLabel">
                    Add Role
                </h5>
                <button type="button" class="btn-close" aria-label="Close" (click)="hide()"></button>
            </div>
            <div class="modal-body">
                <form [formGroup]="form" novalidate>
                    <div class="mb-3">
                        <label for="inputRoleName" class="form-label">Name</label>
                        <input type="email" class="form-control" id="inputRoleName" formControlName="name" aria-describedby="inputRoleNameHelp">
                        <!-- <div id="inputRoleNameHelp" class="form-text">We'll never share your email with anyone else.</div> -->
                    </div>
                    <div class="mb-3">
                        <label for="inputRoleSlug" class="form-label">Slug</label>
                        <input type="email" class="form-control" id="inputRoleSlug" formControlName="slug" aria-describedby="inputRoleSlugHelp">
                        <!-- <div id="inputRoleSlugHelp" class="form-text">We'll never share your email with anyone else.</div> -->
                    </div>
                    <div class="mb-3">
                        <label for="inputRoleDescription" class="form-label">Description</label>
                        <input type="email" class="form-control" id="inputRoleDescription" formControlName="description" aria-describedby="inputRoleDescriptionHelp">
                        <!-- <div id="inputRoleDescriptionHelp" class="form-text">We'll never share your email with anyone else.</div> -->
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-danger" [disabled]="!form.valid" (click)="onSubmit()">
                    Store
                </button>
            </div>
        </div>
    </div>
</div>