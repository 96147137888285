
<div class="row mb-4">
    <ng-container *ngIf="(formFile); then renderFileSelected; else renderNoFileSelected"></ng-container>
</div>

<input #inputFileRef type="file" [multiple]="multiple" [accept]="accept" style="display: none;" (change)="onFileSelected()">


<ng-template #renderFileSelected>
    <div class="col-12">
        <div class="card-box card-box-b3 p-3 mb-3">
            <!-- <div class="row g-2 d-flex align-items-center">
                <div class="col-12 px-3"> -->

                    <p class="card-text text-center mb-0">
                        {{ "general.fileName" | translate }}:
                        <small class="text-muted">
                            {{formFile?.name}}
                        </small>
                    </p>

                    <p class="card-text text-center mb-0">
                        {{ "general.fileType" | translate }}:
                        <small class="text-muted">
                            {{formFile?.type}}
                        </small>
                    </p>

                    <p class="card-text text-center mb-0">
                        {{ "general.size" | translate }}:
                        <small class="text-muted">
                            <!-- {{formFile?.size}} -->
                            {{sizeParsed}}
                        </small>
                    </p>

                <!-- </div>
            </div> -->
        </div>
    </div>
    <div class="col-12 mb-2 mb-md-0 d-flex justify-content-end">
        <button type="button" class="btn btn-two me-3" (click)="clearFile()">
            <i class="bi bi-trash"></i>
            &nbsp;
            {{clearBtnText | translate}}
        </button>
        <!-- <button type="button" class="btn btn-one" (click)="onConfirm()">
            {{confirmBtnText | translate}}
            &nbsp;
            <i class="bi bi-cloud-upload"></i>
        </button> -->
    </div>
</ng-template>

<ng-template #renderNoFileSelected>
    <div class="col-12 part1">
        <div class="card-box card-box-b3 p-3 mb-3">
            <span class="text-center">
                {{noSelectFileText  | translate}}
            </span>
        </div>
    </div>
    <div class="col-12 part2">
        <button type="button" class="btn btn-one" (click)="openFileSelection()">
            <i class="bi bi-folder"></i>
            &nbsp;
            {{uploadBtnText  | translate}}
        </button>
    </div>
</ng-template>