<div class="container">
    <div class="row">
        <div class="col pt-5">
            <div class="logo d-flex justify-content-center align-items-center">
                <img src="assets/img/cilik/footer-logo.png" alt="">
            </div>
        </div>
    </div>
    <div class="col-12">
        <div class="icon-payments d-flex justify-content-center">
            <img src="assets/img/cilik/mastercard.svg" alt="">
            <img src="assets/img/cilik/mcsc.svg" alt="">
            <img src="assets/img/cilik/paypal.svg" alt="">
            <img src="assets/img/cilik/verified.svg" alt="">
            <img src="assets/img/cilik/Visa.svg" alt="">
        </div>
    </div>
    <div class="col-12 text-center mt-2">
        <small>Version {{currentVersion}}</small>
    </div>
</div>