<div class="row mb-4">
  <div class="col-12 mb-4">
    <p>
      <!-- Para tu comodidad y seguridad al realizar pagos en línea, te ofrecemos la
      opción de usar PayPal, una plataforma líder mundialmente reconocida. -->
      {{ "checkout.text-paypal" | translate }}
    </p>
  </div>
</div>

<div class="row justify-content-center">
  <div class="d-flex justify-content-center">
    <ngx-paypal
      style="width: 77%"
      [config]="payPalConfig"
      #paypalWidget
    ></ngx-paypal>
  </div>
</div>
