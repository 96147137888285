<div class="modal fade modal-RoomAdditionalDays" id="modalRoomAdditionalDays" data-bs-backdrop="static" tabindex="-1" aria-labelledby="modalRoomAdditionalDaysLabel" aria-hidden="true">
    <div class="modal-dialog modal-fullscreen">
        <div class="modal-content">

            <div class="modal-header justify-content-center">
                <h5 class="modal-title fw-bold">
                    {{ "general.extraNight" | translate }} 
                </h5>

            </div>

            <div class="modal-body">
                <div class="container">
                    <div class="row g-3">

                        <ng-container *ngIf="(list$ | async) as list; else loading">

                            <div class="col-12 mb-4">
                                <div class="card card-box" >
                                    <div class="card-body">
                                        <h5 class="card-title fw-bolder">
                                            {{'hotel.ubitacion.'+item.ubicationType | translate | uppercase}}
                                        </h5>
                                        <p class="card-text">
                                            {{ 'hotel.roomType.'+item.roomCode | translate | uppercase}}
                                        </p>
                                        <p class="card-text">
                                            <small class="text-muted">
                                                {{ "general.pricePerPerson" | translate }}: 
                                                <i class="bi" [ngClass]="{'bi-person-fill': item?.capacity == 1, 'bi-people-fill': item?.capacity > 1}"></i>
                                                USD {{ (list.price / item.capacity) | mask:'separator.0':',' }}
                                            </small>
                                        </p>
                                        <p class="card-text fw-bolder">
                                            {{ "general.valueWithoutDiscount" | translate }} <span class="fw-bold text-decoration-line-through">USD {{list.fullPrice | mask:'separator.0':','}}</span>
                                        </p>
                                        <p class="card-text fw-bolder">
                                            {{ "roomsStep.amountPerAdditionalNight" | translate }}: USD {{list.price | mask:'separator.0':','}}
                                        </p>
                                        <p class="card-text">
                                            {{ "roomsStep.additionalNightAddText" | translate }}
                                        </p>
                                        <div class="row">
                                            <div class="col-12 col-md-6 mb-3">
                                                <app-input-number-form
                                                    [min]="0" [max]="5" [quantity]="daysBefore"
                                                    [label]=" '# ' + ('roomsStep.nightsBefore' | translate)"
                                                    (onUpdateQuantity)="onDaysBefore({data: list, quantity: $event})"></app-input-number-form>
                                            </div>
                                            <div class="col-12 col-md-6 mb-3">
                                                <app-input-number-form
                                                    [min]="0" [max]="5" [quantity]="daysAfter"
                                                    [label]=" '# ' + ('roomsStep.nightsAfter' | translate)"
                                                    (onUpdateQuantity)="onDaysAfter({data: list, quantity: $event})"></app-input-number-form>
                                            </div>
                                            <div class="col-12">
                                                <h5 class="text-start text-md-end">
                                                    {{ "roomsStep.totalPerNights" | translate }}: <span class="fw-bold fs-4">USD {{additionalDaysAmount | mask:'separator.0':','}} </span>
                                                </h5>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </ng-container>

                        <div class="col-12">
                            <hr>
                        </div>
                        <div class="col-12 col-md-6 mb-2 mb-md-0">
                            <h5>
                                <span class="badge bg-danger">
                                    {{ "general.capacity" | translate }}: 
                                    <i class="bi" [ngClass]="{'bi-person-fill': item?.capacity == 1, 'bi-people-fill': item?.capacity > 1}"></i> {{ item?.capacity }}
                                </span>
                            </h5>
                        </div>
                        <div class="col-12 col-md-6 mb-2 mb-md-0">
                            <h5 class="text-start text-md-end">
                                Sub-Total: <span class="fw-bold fs-2">USD {{subTotal | mask:'separator.0':','}} </span>
                            </h5>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer flex-column justify-content-center">
                <div class="w-50 d-flex flex-row justify-content-center align-items-center">
                    <button type="button" class="btn btn-one" (click)="closeModal()">
                        {{ "general.back" | translate }}
                    </button>
                </div>

            </div>
        </div>
    </div>
</div>


<ng-template #loading>
    <div class="col-12 mb-4">
        <div class="card" >
            <div class="card-body">
                <h5 class="card-title fw-bolder placeholder-glow">
                    <span class="placeholder col-6" style="height: 16px;"></span>
                </h5>
                <p class="card-text placeholder-glow">
                    <span class="placeholder col-10" style="height: 16px;"></span>
                </p>
                <p class="card-text placeholder-glow">
                    <span class="placeholder col-8" style="height: 16px;"></span>
                </p>
                <p class="card-text placeholder-glow">
                    <span class="placeholder col-5" style="height: 16px;"></span>
                </p>
                <p class="card-text placeholder-glow">
                    <span class="placeholder col-12" style="height: 16px;"></span>
                </p>
                <div class="row">
                    <div class="col-12 col-md-6" *ngFor="let item of [1,1]">
                        <label class="form-label w-100 text-center placeholder-glow">
                            <span class="placeholder col-6"></span>

                        </label>
                        <div class="input-group mb-3">
                            <button class="btn btn-sm btn-outline-secondary" type="button" id="button-addon1" disabled>
                                <i class="bi bi-dash"></i>
                            </button>
                            <input type="text" class="form-control text-center" placeholder="00" disabled aria-label="Example text with button addon" aria-describedby="button-addon1">
                            <button class="btn btn-sm btn-outline-secondary" type="button" id="button-addon1" disabled>
                                <i class="bi bi-plus"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>