<div class="presale-steps">
  <div class="row align-items-center">
    <div class="seprd"></div>
    <div class="col d-flex align-items-center">
      <div class="avtr">
        <img src="assets/img/cilik/icon-39.png" alt="" />
      </div>
      <div>
        <ng-container *ngIf="profile$ | async as profile; else renderLoader" [ngTemplateOutlet]="renderInfo"
          [ngTemplateOutletContext]="{profile}">
        </ng-container>
      </div>
      <br />
    </div>
    <div class="seprd"></div>
  </div>
</div>

<ng-template #renderInfo let-profile="profile">
  <div class="fs-4 fw-bold ps-3 d-flex jsutify-content-center flex-column">
    <div class="d-flex">
      {{ "general.hello" | translate }} {{ profile?.name | translate | titlecase }}
      <a  class="ps-2" routerLink="/pages/profile">- {{'Profile' | translate | titlecase}} </a>
      <ng-container *ngIf="_isAdmin">
        <a class="ps-2" routerLink="/admin"> - Admin</a>
      </ng-container>



    </div>

    <div class="fw-bold d-flex jsutify-content-center flex-column">
      <!-- <div class="d-flex">
        {{ "general.hello" | translate }} {{ profile?.name | titlecase }}
        <ng-container *ngIf="_isAdmin">
          <a class="ps-2" routerLink="/admin"> - Admin</a>
        </ng-container>
      </div> -->

      <ng-container *ngIf="isAnonymous$ | async as isAnonymous">
        <ng-container *ngIf="isAnonymous">
          <a class="text-rslt fs-6" [routerLink]="['/auth/sign-in']"
            routerLinkActive="router-link-active">{{'general.logIn' | translate}} </a>
        </ng-container>
      </ng-container>

      <a [routerLink]="backTo" class="text-rslt fs-6" *ngIf="showDashboardBtn">
        {{'general.goToDashboard' | translate}}


      </a>
    </div>
  </div>
</ng-template>

<ng-template #renderLoader>
  <div class="fs-2 fw-bold ps-3 placeholder-glow">
    <span class="placeholder col-12" style="height: 16px; width: 20rem;"></span>
    <span class="placeholder text-danger col-12" style="font-size: 18px;"></span>
  </div>
</ng-template>